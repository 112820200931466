import styled from "styled-components";
import { Spinner } from "reactstrap";
import { Container, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import DropDown from "../../components/DropDown";
import { useTranslation } from "react-i18next";
import NFTs from "../Profile/NFTs";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  appActionsCreators,
  moralisActionCreators,
  nftActionsCreators,
} from "../../store";
import { useWeb3Auth } from "../../context/Web3auth";
import InfiniteScroll from "react-infinite-scroll-component";
import { NftCard } from "../NftCard";
import { InfoTooltip } from "@components";
import axios from "axios";
import keys from "../../lib/config";
import { chainIds } from "../../common/constants/chainIds";

const Wrapper = styled.div`
  h4 {
  }
  .nft-div {
    padding: 0 18px;
    margin-bottom: 36px;
  }
  .wallet-btn {
    font-size: 14px;
    color: #777e90;
  }
  .wallet-btn.active {
    color: #fff;
    background-color: #353945;
  }
`;

const NFT = styled.div`
  box-shadow: rgba(31, 47, 70, 0.12) 0px 8px 32px 0px;
  border-radius: 20px;
  padding: 12px;
  position: relative;
  cursor: pointer;
  img {
    width: 100%;
    border-radius: 20px;
  }
  .title {
    margin-top: 18px;
    font-weight: 500;
  }
  .icons {
    position: absolute;
    display: flex;
    opacity: 0;
    top: 27px;
    right: 27px;
    transition: all 0.2s;
    button {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: white;
      border: none;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  &:hover {
    .icons {
      opacity: 1;
    }
  }
`;

const LinkedNft = () => {
  const { t, i18n } = useTranslation();
  const options = [
    { label: "Meta Mask", id: "0" },
    { label: "Coinbase", id: "1" },
    { label: "Wallet Connect", id: "2" },
  ];

  const dispatch = useDispatch();
  const { getLinkedNftsCreator } = bindActionCreators(
    moralisActionCreators,
    dispatch
  );
  const { changeChainCreator } = bindActionCreators(
    appActionsCreators,
    dispatch
  );

  const app = useSelector((state) => state.app);

  const auth = useSelector((state) => state.auth);
  const [linkednfts, setLinkednfts] = useState([]);
  const [metaData, setMetaData] = useState("");

  const { loading, nfts, has_more, cursor } = useSelector(
    (state) => state.linkedNfts
  );

  const { onChangeChain, chain } = useWeb3Auth();

  const { account } = useWeb3React();
  useEffect(
    () =>
      getLinkedNftsCreator(
        account,
        cursor
          ? {
              cursor,
              chain: chain === "ETH" ? "mainnet" : "polygon",
              limit: 24,
            }
          : { chain: chain === "ETH" ? "mainnet" : "polygon", limit: 24 }
      ),
    [account, chain]
  );
  useEffect(() => {
    let response;
    let ChainCode;

    if (window.ENV_NAME.trim() === "development") {
      if (chain === "MATIC") {
        ChainCode = chainIds.mumbai;
      } else if (chain === "ETH") {
        ChainCode = chainIds.sepolia;
      }
    } else if (window.ENV_NAME.trim() === "staging") {
      if (chain === "MATIC") {
        ChainCode = chainIds.polygon;
      } else if (chain === "ETH") {
        ChainCode = chainIds.eth;
      }
    } else if (window.ENV_NAME.trim() === "production") {
      if (chain === "MATIC") {
        ChainCode = chainIds.polygon;
      } else if (chain === "ETH") {
        ChainCode = chainIds.eth;
      }
    } else if (window.ENV_NAME.trim() === "local") {
      if (chain === "MATIC") {
        ChainCode = chainIds.mumbai;
      } else if (chain === "ETH") {
        ChainCode = chainIds.sepolia;
      }
    }
    async function getData() {
      response = await axios
        .get(`${window.API_URL}/getnfts`, {
          params: { account, ChainCode },
        })
        .then((response) => {
          setLinkednfts(response.data);
        });
    }
    if (account) getData();
  }, [chain, account]);
  useEffect(() => {
    if (linkednfts.result) {
      const metaDataTest = linkednfts.result?.contract_type
        ? JSON.parse(linkednfts.result.metadata)
        : linkednfts.result.metadata;
      setMetaData(metaDataTest);
    }
  }, [linkednfts]);
  const filtered = Array.isArray(linkednfts.result)
    ? linkednfts.result.filter((linkednft) => {
        return linkednft?.contract_type
          ? JSON.parse(linkednft.metadata)?.image
          : linkednft?.metadata?.image &&
              linkednft?.name &&
              !(
                linkednft?.contract_type
                  ? JSON.parse(linkednft.metadata)?.image
                  : linkednft?.metadata?.image
              )?.includes(".mp4") &&
              !(
                linkednft?.contract_type
                  ? JSON.parse(linkednft.metadata)?.image
                  : linkednft?.metadata?.image
              )?.includes(".mp3");
      })
    : [];
  const filtered2 = Array.isArray(linkednfts)
    ? linkednfts.filter((linkednft) => {
        return (
          linkednft?.metadata?.image &&
          linkednft?.name &&
          !linkednft?.metadata?.image?.includes(".mp4") &&
          !linkednft?.metadata?.image?.includes(".mp3")
        );
      })
    : [];
  return (
    <Wrapper>
      <Row>
        <Col xs={12} md={6}>
          <div className="mb-4 page-header-md">
            {t("Linked NFTs")}
            <InfoTooltip text={t("wallet.linked_nfts_tooltip")} />
          </div>
        </Col>
        <Col xs={12} md={6} style={{ textAlign: "end" }}>
          <ButtonGroup>
            <Button
              style={{
                background: app.chain !== "polygon" ? "#6d6e71" : "#6d3190",
                borderColor: app.chain !== "polygon" ? "#6d6e71" : "#6d3190",
              }}
              onClick={() => {
                onChangeChain("MATIC");
                changeChainCreator("polygon");
              }}
            >
              {loading && app.chain === "polygon" && (
                <Spinner size="sm" color="#fff" />
              )}{" "}
              {t("Polygon")}
            </Button>
            <Button
              style={{
                background: app.chain !== "mainnet" ? "#6d6e71" : "#6d3190",
                borderColor: app.chain !== "mainnet" ? "#6d6e71" : "#6d3190",
              }}
              onClick={() => {
                onChangeChain("ETH");
                changeChainCreator("mainnet");
              }}
            >
              {loading && app.chain === "mainnet" && (
                <Spinner size="sm" color="#fff" />
              )}{" "}
              {t("Ethereum")}
            </Button>
          </ButtonGroup>
        </Col>

        {/* <Col xs={12} lg={8}>
          <div className="d-none d-lg-flex justify-content-end">
            <a
              href="#"
              className="btn btn-secondary-outline radius-50 me-2 wallet-btn active"
            >
              <img src={metaMask} alt="" className="me-2" />
              Meta Mask
            </a>
            <a
              href="#"
              className="btn btn-secondary-outline radius-50 me-2 wallet-btn"
            >
              <img src={coinbase} alt="" className="me-2" />
              Coinbase
            </a>
            <a
              href="#"
              className="btn btn-secondary-outline radius-50 wallet-btn"
            >
              <img src={walletConnect} alt="" className="me-2" />
              Wallet Connect
            </a>
          </div>
        </Col> */}
      </Row>
      <div className="d-block d-lg-none mt-2 mb-5">
        <DropDown label="Meta Mask" options={options} />
      </div>
      <Row>
        <InfiniteScroll
          style={{ width: "100%", padding: "10px 0", overflow: "none" }}
          dataLength={filtered?.length}
          next={() =>
            getLinkedNftsCreator(
              account,
              cursor
                ? {
                    cursor,
                    chain: chain === "ETH" ? "mainnet" : "polygon",
                    limit: 24,
                  }
                : { chain: chain === "ETH" ? "mainnet" : "polygon", limit: 24 }
            )
          }
          hasMore={has_more}
          endMessage=""
        >
          <Row>
            {linkednfts.result
              ? linkednfts.result.map((el) => {
                  const nft = {
                    creators: {
                      minter: {
                        name: auth?.account?.name,
                        profile: auth?.account?.profile,
                      },
                    },
                    ...el,
                  };
                  return (
                    <Col
                      xs={12}
                      md={6}
                      lg={4}
                      style={{ margin: "10px 0" }}
                      key={Math.random()}
                    >
                      <NftCard item={nft} />
                    </Col>
                  );
                })
              : filtered2.map((el) => {
                  const nft = {
                    creators: {
                      minter: {
                        name: auth?.account?.name,
                        profile: auth?.account?.profile,
                      },
                    },
                    ...el,
                  };
                  return (
                    <Col
                      xs={12}
                      md={6}
                      lg={4}
                      style={{ margin: "10px 0" }}
                      key={Math.random()}
                    >
                      <NftCard item={nft} />
                    </Col>
                  );
                })}
          </Row>
        </InfiniteScroll>

        {/* {loading ? (
          <Spinner color="#6d3190" />
        ) : (
          <div>
            {
              nfts.length === 0? <p>No results</p> : <NFTs nfts={nfts} noActions={true} />
            }
          </div>
        )} */}
      </Row>
      {loading && (
        <Container>
          <p>Loading...</p>
        </Container>
      )}
    </Wrapper>
  );
};

export default LinkedNft;
