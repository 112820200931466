import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Image,
  InputGroup,
} from "react-bootstrap";
import { useFormik } from "formik";
import {
  chains,
  NIFTYSouq721,
  NIFTYSouq1155,
  NIFTYSouqMarketPlace,
  NIFTYSouqManager,
} from "@config";
import { useWeb3Auth } from "@context/Web3auth";
import WalletModal from "@components/WalletModal";
import { useWeb3Context } from "@context/Web3";
import { useWeb3React } from "@web3-react/core";
//svg file
import ModalAndOverlay from "@components/ModalAndOverlay";
import DeleteRowIcon from "@assets/icons/delete-row.svg";
import AddRowIcon from "@assets/icons/add-row.svg";
import lockOrderIcon from "@assets/images/icons/lock-order-circle.svg";
import completedIcon from "@assets/images/icons/completed.svg";
import arrowRight from "../../icons/arrow-right.svg";
import closeIcon from "../../../src/icons/close-circle.svg";
import addIcon from "../../icons/addIcon.png";
import { ReactComponent as Expand } from "@assets/icons/expand.svg";
//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  nftActionsCreators,
  walletActionsCreators,
  authActionsCreators,
  profileActionsCreators,
  creatorCollectionsCreators,
} from "../../store";
import { PreviewNftCard } from "@components/PreviewNftCard";
import CreateCollectionPopup from "../CreateCollectionPopup";
import ChooseCollection from "@components/searchBox/SearchBox";
import { ImageModal, InfoTooltip } from "@components";
import {
  createToken,
  mintToken,
  editMintToken,
  uploadFileToPreview,
  createTokenFiat,
} from "../../core/services/nft";
import { nftDetailsEvents, socketChannels } from "../../common/sockets";
import { useSubscription } from "../../socket";
import {
  initialValues,
  createNFTValidationSchema,
  editNFTValidationSchema,
} from "./data";
import WAValidator from "wallet-address-validator";
import StylesWrapper from "./styles";
import Swal from "sweetalert2";
import { getAccountPath } from "ethers/lib/utils";
import keys from "../../lib/config";
import Web3 from "web3";

export const NFTForm = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { state } = useLocation();
  const { nft } = props;
  const editNFT = nft && nft?._id;
  const { collectionId } = useParams();
  const action_data = useSelector((state) => state.nft_actions);
  const auth_account = useSelector((state) => state.auth);
  const { default_wallet, another_wallet } = useSelector(
    (state) => state.wallets
  );
  const { categories } = useSelector((state) => state.category);
  const { collections, collectionCreated } = useSelector(
    (state) => state.creatorCollections
  );
  const {
    createNFTCreator,
    updateNFTCreator,
    createNftProgressCreator,
    approveNftCreator,
    resetNFTActionsData,
    generateIPFSNFTCreator,
  } = bindActionCreators(nftActionsCreators, dispatch);

  const { saveWalletsCreator } = bindActionCreators(
    walletActionsCreators,
    dispatch
  );
  const { importWalletCreator } = bindActionCreators(
    authActionsCreators,
    dispatch
  );
  const { getUserProfile } = bindActionCreators(
    profileActionsCreators,
    dispatch
  );

  const { fetchUserCollectionCreator, resetCollectionFlags } =
    bindActionCreators(creatorCollectionsCreators, dispatch);

  const { chainId, account } = useWeb3React();
  const { mint, mintList, mintCreateAuction } = useWeb3Context();
  const {
    userPrincipal,
    privateKey,
    getAccounts,
    chain,
    onChangeChain,
    getAlgoAccount,
    mintDefaultAlgorand,
  } = useWeb3Auth();
  const [algo_wallet, setAlgoWallet] = useState("");
  const [step, setStep] = useState(false);
  const [contract, setContract] = useState("");
  const [isFiatModal, setFiatPayModal] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [defaultWallet, setDefaultWallet] = useState("");
  const [selectedWallet, setSelectWallet] = useState("");
  const [isOpenCreateCollection, setOpenCreateCollection] = useState(false);
  const [sumRevenue, setSumRevenue] = useState(0);
  const [sumRoyalties, setSumRoyalties] = useState(0);
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  const [wertPaymentStatus, setWertPaymentStatus] = useState("pending");
  const [walletBalance, setWalletBalance] = useState(0);
  const [currency, setCurrency] = useState(chain);

  //partners
  const inputFile = useRef();
  const partnersForm = useRef();
  const [editMode, setEditMode] = useState(true);
  const [partnersFormValid, setPartnersFormValid] = useState(true); //partners
  const [formValues, setFormValues] = useState([]); //partners
  const [imageModal, setImageModal] = useState(false);

  const maxRevenue = 10;
  const maxRoyalty = 10;

  useSubscription(
    `${socketChannels.CREATING_NFT}_${auth_account?.account?._id}_creating`,
    nftDetailsEvents
  );
  useEffect(() => {
    if (collectionId) setFieldValue("collection", { _id: collectionId });
  }, [collectionId]);

  useEffect(() => {
    if (nft?._id) {
      setFieldValue("isLazymint", true);

      const totalRoyalties = nft.royalties?.total_royalties / 100;

      setFieldValue("nft", { file_reader: nft.url, file_type: nft.type });

      setFieldValue("name", nft.name);
      setFieldValue("description", nft.description);
      if (nft.categories && nft.categories?.length > 0)
        setFieldValue("category", nft.categories[0]);

      setFieldValue("chain", nft.blockchain);
      setFieldValue(
        "creator",
        nft?._id ? nft?.creators?.minter?.create_wallet : nft?.creators
      );
      setFieldValue("totalRoyalty", totalRoyalties);
      setFieldValue("isList", nft.listing.sale_type > 0);
      setFieldValue(
        "listType",
        nft.listing.sale_type == 2 ? "auction" : "fixedPrice"
      );
      if (nft.listing.sale_type == 2) {
        setFieldValue("startBidPrice", nft.listing.min_bid);
        setFieldValue("reservePrice", nft.listing.price);
        setFieldValue("day", nft.listing.auction_duration);
      }
      if (nft.listing.sale_type == 1 || nft.listing.sale_type == 0) {
        setFieldValue(
          "price",
          nft.token_standard == "1155"
            ? nft.editions_details?.nft_details?.price || nft?.listing?.price
            : nft?.listing?.price
        );
        setFieldValue(
          "amount",
          nft.token_standard == "1155"
            ? nft.editions_details?.count || nft?.editions?.current
            : 1
        );
      }
      if (nft.creators?.partners?.length > 0) {
        const partners = [];
        const oldPartners =
          //check if there is royalty for creator
          nft.creators?.partners?.length == nft.royalties?.royalties?.length
            ? nft.royalties?.royalties
            : nft.royalties?.royalties.slice(1);

        oldPartners.map((r, i) => {
          partners.push({
            ...r,
            isValid: true,
            revenue: Number(nft?.revenues[i]) / 1000,
            royalty: (Number(r.royalty) * 10) / Number(totalRoyalties),
          });
        });
        Promise.all(partners).then(function () {
          setFormValues(partners);
        });
        setEditMode(nft.creators?.partners?.length > 0 ? false : true);
        setFieldValue(
          "isPartners",
          nft.creators?.partners?.length > 0 ? true : false
        );
      }
      // setEditMode(false);

      if (nft?.creator_collectionId) {
        const collectionItem = collections.find(
          (item) => item._id === nft?.creator_collectionId
        );
        setFieldValue("isCollection", true);
        setFieldValue("collection", collectionItem);
      }
    }
  }, [nft]);

  const createNFTToken = async () => {
    if (nft?._id) {
      if (
        values.name.trim() !== nft.name.trim() ||
        values.description.trim() !== nft.description.trim()
      ) {
        generateIPFSNFTCreator(
          {
            name: values.name,
            description: values.description,
          },
          nft._id
        );
      } else editNFTToken();
    } else {
      const { allData } = getData();

      const inputs = {
        ...values,
        allData,
        currency: currency === "" ? chain : "USDC",
        chain,
        contract:
          values.amount > 1
            ? NIFTYSouq1155.address[chain]
            : NIFTYSouq721.address[chain],
        selectedWallet,
        defaultWallet,
        algo_wallet,
        selectedCollection: values.collection,
        saleType: !values.isList ? 0 : values.listType == "fixedPrice" ? 1 : 2,
        file: values.nft?.file,
        isMultiple: values.amount > 1,
        endDate: values.day * 86400,
        mint,
        mintList,
        mintCreateAuction,
        NIFTYSouqMarketPlace,
        NIFTYSouqManager,
        NIFTYSouq721,
        NIFTYSouq1155,
      };
      await createToken(
        createNftProgressCreator,
        inputs,
        setModalOpen,
        createNFTCreator
      );
    }
  };
  const editNFTToken = async (ipfs_path = "") => {
    const { allData, bodyRoyalties, totalRoyalties } = getData();

    const editInputs = {
      ...values,
      nft_id: nft._id,
      allData,
      currency: currency === "" ? chain : "USDC",
      chain,
      contract,
      selectedWallet,
      defaultWallet,
      selectedCollection: values.collection,
      saleType: !values.isList ? 0 : values.listType == "fixedPrice" ? 1 : 2,
      isMultiple: values.amount > 1,
      endDate: values.day * 86400,
      mint,
      mintList,
      mintCreateAuction,
      NIFTYSouqMarketPlace,
      NIFTYSouqManager,
      NIFTYSouq721,
      NIFTYSouq1155,
      privateKey,
      account,
      royalties: {
        total_royalties: totalRoyalties,
        royalties: bodyRoyalties,
      },
      fileUrl: ipfs_path ? ipfs_path : nft.ipfs_path,
      is_lazy: values.isLazymint,
    };
    await editMintToken(editInputs, updateNFTCreator);
  };

  const createNFT = async () => {
    const { allData } = getData();
    if (values.paymentType == "card") {
      setStep(0);
      setFiatPayModal(true);
    } else await createNFTToken();
  };
  //formik
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    isSubmitting,
    submitCount,
    isValid,
  } = useFormik({
    initialValues,
    onSubmit: createNFT,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: nft?._id
      ? editNFTValidationSchema(t)
      : createNFTValidationSchema(t),
  });

  useEffect(() => {
    onChangeSumRoyalties();
    onChangeSumRevenue();
  }, [formValues]);

  useEffect(() => {
    if (values.totalRoyalty == 0)
      setFormValues(formValues.map((f) => ({ ...f, royalty: 0 })));
  }, [values.totalRoyalty]);

  useEffect(() => {
    if (values.amount > 1) setFieldValue("listType", "fixedPrice");
  }, [values.amount]);

  useEffect(() => {
    if (!isSubmitting) return;

    if (Object.keys(errors)?.length > 0) {
      const errorElemnt = document
        .getElementById("create-nft")
        .querySelector(`#${Object.keys(errors)[0]}`);
      errorElemnt?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (collectionCreated) {
      setOpenCreateCollection(false);
      Swal.fire({
        icon: "success",
        text: t("collection_created"),
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: t("close"),
        didClose: () => dispatch(resetCollectionFlags),
        confirmButtonColor: "#6d3190",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionCreated]);

  useEffect(() => {
    if (action_data?.data && action_data?.action == "create") startMinting();
    if (action_data?.progress == 100 && action_data?.action == "update")
      setEditModalOpen(true);
  }, [action_data?.action]);

  useEffect(() => {
    if (action_data?.ipfs_path) editNFTToken(action_data?.ipfs_path);
  }, [action_data?.ipfs_path]);

  useEffect(() => {
    dataload();
  }, [userPrincipal, privateKey, account, chain]);

  useEffect(() => {
    dataloadOnce();
    return () => resetNFTActionsData();
  }, []);

  const onChangeSumRoyalties = () => {
    const partnersRoyalty = formValues
      .map((item) => Number(item.royalty))
      .reduce((prev, next) => prev + next, 0);
    setSumRoyalties(partnersRoyalty);
  };

  const onChangeSumRevenue = () => {
    const partnersRevenue = formValues
      .map((item) => Number(item.revenue))
      .reduce((prev, next) => prev + next, 0);
    setSumRevenue(partnersRevenue);
  };

  const onValidatePartners = () => {
    let isValid = true;
    let newFormValues = [...formValues];
    newFormValues.map((f) => {
      var validAddress = !!f.creator && WAValidator.validate(f.creator, "ETH");
      var validPercentage = f.royalty > 0 || f.revenue > 0;

      const addresses = auth_account.account.wallets.map((address) =>
        address.toLowerCase()
      );
      var matchCreator =
        !!f.creator && addresses.includes(f.creator.trim().toLowerCase());

      var valid = validAddress && validPercentage && !matchCreator;

      f.isValid = valid;
      f.matchCreator = matchCreator;

      if (!valid) isValid = false;
    });

    setFormValues(newFormValues);
    return isValid;
  };

  const onSubmitPartners = () => {
    const isValid = onValidatePartners();

    setPartnersFormValid(isValid);

    if (isValid) setEditMode(!editMode);
  };
  //ex: 50% = 5 => 5 * 5 = 25 % 10 = 2.5
  const getRoyaltyFromTotal = (v) =>
    (Number(v) * Number(values.totalRoyalty)) / 10;

  const getData = () => {
    const creatorRoyalty =
      values.totalRoyalty == 0
        ? 0
        : formValues?.length == 0
        ? maxRoyalty
        : maxRoyalty - sumRoyalties;

    let allData = {
      creators: creatorRoyalty > 0 ? [values.creator] : [],
      royalties:
        creatorRoyalty > 0 ? [getRoyaltyFromTotal(creatorRoyalty) * 100] : [],
      investors: [],
      revenues: [],
      partners: [],
    };

    const bodyRoyalties =
      creatorRoyalty > 0
        ? [
            {
              creator: values.creator,
              royalty: getRoyaltyFromTotal(creatorRoyalty),
            },
          ]
        : [];

    formValues.map((formValue) => {
      allData.partners.push(formValue.creator);
      creatorRoyalty > 0
        ? allData.creators.push(formValue.creator)
        : console.log("No change");
      allData.investors.push(formValue.creator);
      creatorRoyalty > 0
        ? allData.royalties.push(getRoyaltyFromTotal(formValue.royalty) * 100)
        : console.log("No change");
      allData.revenues.push(Number(formValue.revenue) * 1000);
      bodyRoyalties.push({
        creator: formValue.creator,
        royalty: isNaN(getRoyaltyFromTotal(formValue.royalty))
          ? 0
          : getRoyaltyFromTotal(formValue.royalty),
      });
    });

    return {
      allData,
      bodyRoyalties,
      totalRoyalties: Number(values.totalRoyalty) * 100,
    };
  };

  // Partners
  const handelFormChange = (e, i) => {
    let data = [...formValues];
    let item = data[i];
    item[e.target.name] = e.target.value;
    setFormValues(data);
  };

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      { creator: "", royalty: 0, revenue: 0, isValid: true },
    ]);
  };

  const removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  //call mint method in contract
  const dataloadOnce = async () => {
    if (auth_account?.account?._id) {
      getUserProfile(auth_account.account._id);
      fetchUserCollectionCreator({ creatorId: auth_account.account._id });
    }
  };

  const dataload = async () => {
    //get default wallet
    const algo_address = await getAlgoAccount();
    setAlgoWallet(algo_address);
    const wallet = await getAccounts();
    auth_account.account &&
      wallet.address &&
      !auth_account.account?.wallets.includes(wallet.address) &&
      importWalletCreator({ wallet: wallet.address });
    saveWalletsCreator(0, wallet.address);
    if (nft?._id) setSelectWallet(nft?.creators?.minter);
    else setSelectWallet(wallet.address);
    setDefaultWallet(wallet.address);
    const wallets = [];
    wallets.push({ value: wallet.address, label: wallet.address });
    if (account) {
      if (chains[chain].chainId === chainId) {
        wallets.push({ value: account, label: account });
      }
    }
    //contrat setting
    if (values.amount > 1) setContract(NIFTYSouq1155.address[chain]);
    else setContract(NIFTYSouq721.address[chain]);
  };

  async function startMinting() {
    if (action_data.data) {
      const { allData, bodyRoyalties, totalRoyalties } = getData();
      const inputs = {
        ...values,
        allData,
        algo_wallet,
        chain,
        currency: currency === "" ? chain : "USDC",
        contract,
        selectedWallet,
        defaultWallet,
        selectedCollection: values.collection,
        saleType: !values.isList ? 0 : values.listType == "fixedPrice" ? 1 : 2,
        file: values.nft?.file,
        isMultiple: values.amount > 1,
        endDate: values.day * 86400,
        mint,
        mintDefaultAlgorand,
        mintList,
        mintCreateAuction,
        NIFTYSouqMarketPlace,
        NIFTYSouqManager,
        NIFTYSouq721,
        NIFTYSouq1155,
        privateKey,
        account,
        royalties: {
          total_royalties: totalRoyalties,
          royalties: bodyRoyalties,
        },
        setModalOpen,
      };

      await mintToken(
        createNftProgressCreator,
        {
          ...inputs,
          fileUrl: action_data.data.ipfs_image,
          token: action_data.data.token,
        },
        approveNftCreator
      );
    }
  }

  const disabledField = values.isPartners && editMode;
  const checkChainId = () => {
    if (chains[chain].chainId != chainId) {
      Swal.fire({
        icon: "warning",
        text: t("Please swich chain on Metamask"),
        showConfirmButton: true,
        confirmButtonText: t("close"),
        didClose: () => dispatch(resetCollectionFlags),
        confirmButtonColor: "#6d3190",
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const web3 = new Web3(
        new Web3.providers.HttpProvider(chains[chain].rpcTarget)
      );
      const exbalance = await web3.utils.fromWei(
        (await web3.eth.getBalance(selectedWallet)).toString(),
        "ether"
      );
      setWalletBalance(exbalance);
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedWallet]);

  // checkChainId();
  return (
    <div>
      {connectWalletModal && (
        <WalletModal
          isOpen={connectWalletModal}
          setIsOpen={setConnectWalletModal}
        />
      )}
      {isModalOpen && (
        <ModalAndOverlay>
          <div>
            <div className="d-flex justify-content-between mb-4">
              <h3 className="modal-heading">{t("Follow steps")}</h3>
            </div>
            {/* upload files */}
            <div className="mb-4 pb-2">
              <div className="d-flex align-items-center mb-3">
                <div style={{ minWidth: "48px" }}>
                  {action_data?.status?.upload === "loading" ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <Image
                      width={48}
                      height={48}
                      style={{ maxWidth: "auto" }}
                      src={completedIcon}
                    />
                  )}
                </div>
                &nbsp;&nbsp;
                <div>
                  <h5 className="m-0 modal-section-title">
                    {t("Upload Files")}
                  </h5>
                  <p className="m-0 modal-section-text">
                    {t("Uploading all media assets and metadata to IPFS")}
                  </p>
                  <p
                    className="m-0 modal-section-text"
                    style={{ color: "#6c2e90" }}
                  >
                    {t("uploading: ")}{" "}
                    <span style={{ color: "#121212" }}>
                      {action_data.progress > 100 ? 100 : action_data.progress}%
                    </span>
                  </p>
                  {action_data?.status?.upload === "loading" &&
                    action_data?.error && (
                      <p
                        className="m-0 modal-section-text"
                        style={{ color: "red" }}
                      >
                        {t("Something went wrong with uploading")}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div className="mb-4 pb-2">
              <div className="d-flex align-items-center mb-3">
                <div style={{ minWidth: "48px" }}>
                  {action_data?.status?.mint === "loading" ? (
                    <Spinner animation="border" variant="primary" />
                  ) : action_data?.status?.mint === "done" ? (
                    <Image
                      width={48}
                      height={48}
                      style={{ maxWidth: "auto" }}
                      src={completedIcon}
                    ></Image>
                  ) : (
                    <Image src={lockOrderIcon}></Image>
                  )}
                </div>
                &nbsp;&nbsp;
                <div>
                  {values.isLazymint ? (
                    <h5 className="m-0 modal-section-title">{t("Create")}</h5>
                  ) : (
                    <h5 className="m-0 modal-section-title">
                      {t("Mint")}{" "}
                      {values.isList
                        ? values.listType === "fixedPrice"
                          ? t("& ") + t("Put on sale")
                          : values.listType === "auction"
                          ? t("& ") + t("Create auction")
                          : ""
                        : ""}
                    </h5>
                  )}
                  <p className="m-0 modal-section-text">{t("send_create")}</p>
                  {action_data?.status?.mint === "loading" &&
                    action_data?.error && (
                      <p
                        className="m-0 modal-section-text"
                        style={{ color: "red" }}
                      >
                        {t("Something went wrong with minting")}
                      </p>
                    )}
                </div>
              </div>
            </div>
            <div>
              {action_data?.status?.upload === "done" &&
              action_data?.status?.mint === "done" ? (
                <Button
                  className="modal-button mb-2 mb-md-0"
                  disabled={
                    action_data?.status?.upload === "done" &&
                    action_data?.status?.mint === "done"
                      ? false
                      : true
                  }
                  onClick={() => {
                    createNftProgressCreator(null);
                    navigate(
                      `/nft/${values.amount > 1 ? 1155 : 721}/${
                        action_data.data.nft_id
                      }`
                    );
                    resetNFTActionsData();
                  }}
                >
                  {t(`View NFT`)}
                </Button>
              ) : (
                <Button
                  className="modal-button mb-2 mb-md-0"
                  style={
                    action_data?.status?.mint !== "done"
                      ? {
                          pointerEvents: "none",
                          cursor: "not-allowed",
                          opacity: "0.65",
                        }
                      : {}
                  }
                  onClick={() => {
                    setModalOpen(false);
                  }}
                >
                  {t(`Close`)}
                </Button>
              )}
            </div>
          </div>
        </ModalAndOverlay>
      )}
      {isEditModalOpen && (
        <ModalAndOverlay>
          <div>
            {action_data?.error ? (
              <p
                className="m-0 modal-section-title mb-5"
                style={{ color: "red" }}
              >
                {t("create-nft.edit_nft_error")}
              </p>
            ) : (
              <p className="m-0 modal-section-title mb-5">
                {t("create-nft.edit_nft_success")}
              </p>
            )}
            {action_data?.success ? (
              <Button
                className="modal-button mb-2 mb-md-0"
                onClick={() => {
                  createNftProgressCreator(null);
                  navigate(
                    `/nft/${values.amount > 1 ? 1155 : 721}/${nft?._id}`
                  );
                  resetNFTActionsData();
                }}
              >
                {t(`View NFT`)}
              </Button>
            ) : (
              <Button
                className="modal-button mb-2 mb-md-0"
                onClick={() => setEditModalOpen(false)}
              >
                {t(`Close`)}
              </Button>
            )}
          </div>
        </ModalAndOverlay>
      )}
      <StylesWrapper
        disabledField={disabledField}
        isLTR={i18n.language != "ar"}
      >
        <Container className="create-page" id="create-nft">
          <Row>
            <Col md={7}>
              <div className="create-page-title mb-4">
                {nft?._id
                  ? t("create-nft.edit_nft")
                  : t("create-nft.create_nft")}
              </div>
              <Form onSubmit={handleSubmit}>
                {/* nft upload file */}
                {nft?._id ? null : (
                  <div>
                    <div className="create-section-title">
                      {t("create-nft.upload_file")}
                      <span className="mandatory">*</span>
                    </div>
                    <div className="mb-3">
                      <div className="file-note">
                        {t("create-nft.file_types_supported")}{" "}
                        {t("create-nft.file_types")}
                      </div>
                      <div className="file-note">
                        {t("create-nft.max_size")}
                      </div>
                    </div>
                    <Form.Group className="mb-4">
                      <div className="file-wrapper">
                        <Form.Control
                          type="file"
                          name="nft"
                          id="nft"
                          ref={inputFile}
                          className="nft-input"
                          disabled={disabledField || nft?._id}
                          // onBlur={handleBlur}
                          label={"fileName"}
                          errors={errors.nft}
                          accept="image/jpg, image/jpeg, image/png, image/gif, image/webp, audio/mp3, video/mp4"
                          onChange={async (e) => {
                            const result = await uploadFileToPreview(
                              e.target.files[0]
                            );
                            setFieldValue("nft", result);
                          }}
                        />
                        {values.nft && (
                          <div
                            className="remove cursor"
                            onClick={() => {
                              if (inputFile.current)
                                inputFile.current.value = "";
                              setFieldValue("nft", "");
                            }}
                          >
                            {t("create-nft.remove")}
                          </div>
                        )}
                      </div>
                      {errors.nft && touched.nft && (
                        <Form.Text className="text-danger">
                          {errors.nft}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </div>
                )}
                {/* nft details  name + description*/}
                <div className="create-section-title mt-2">
                  {t("create-nft.nft_details")}
                </div>
                <Form.Group>
                  <Form.Label className="create-section-label pt-2">
                    {t("create-nft.name")}
                    <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    id="name"
                    disabled={disabledField}
                    className="create-page-input-field"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    errors={errors.name}
                    placeholder={t("create-nft.name_placeholder")}
                  />
                  {errors.name && touched.name && (
                    <Form.Text className="text-danger">{errors.name}</Form.Text>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="create-section-label pt-3">
                    {t("create-nft.description")}
                    <span className="mandatory">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={3}
                    name="description"
                    id="description"
                    disabled={disabledField}
                    className="create-page-input-field textarea"
                    style={{ height: 100 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    errors={errors.description}
                    placeholder={t("create-nft.description_placeholder")}
                  />
                  {errors.description && touched.description && (
                    <Form.Text className="text-danger">
                      {errors.description}
                    </Form.Text>
                  )}
                </Form.Group>
                {/* <Row>
                  <Col xs={12} md={6}> */}
                {/* nft category */}
                <Form.Group className="mb-4">
                  <Form.Label className="create-section-label pt-3">
                    {t("create-nft.category")}
                    <span className="mandatory">*</span>
                  </Form.Label>
                  {collectionId ? (
                    <Form.Control
                      disabled
                      name="category"
                      id="category"
                      className="create-page-input-field"
                      value={
                        collections.find((el) => el._id === collectionId)
                          ?.category?.name
                      }
                    />
                  ) : (
                    <Form.Select
                      name="category"
                      id="category"
                      disabled={disabledField}
                      className="create-page-input-field"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.category}
                      errors={errors.category}
                    >
                      {categories.map((item) => {
                        return (
                          <option key={item._id} value={item._id}>
                            {t(item.name)}
                          </option>
                        );
                      })}
                    </Form.Select>
                  )}
                  {errors.category && touched.category && (
                    <Form.Text className="text-danger">
                      {errors.category}
                    </Form.Text>
                  )}
                </Form.Group>
                {/* </Col> */}
                {/* <Col xs={12} md={6}>
                    <Form.Group className="mb-4">
                      <Form.Label className="create-section-label pt-3">
                        {t("create-nft.subcategory")}
                      </Form.Label>
                      {collectionId ? (
                        <Form.Control
                          readOnly
                          name="subcategory"
                          id="subcategory"
                          className="create-page-input-field"
                          value={
                            collections.find((el) => el._id === collectionId)
                              ?.category?.name
                          }
                        />
                      ) : (
                        <Form.Select
                          name="subcategory"
                          id="subcategory"
                          className="create-page-input-field"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.subcategory}
                          errors={errors.subcategory}
                        >
                          {categories.map((item) => {
                            return (
                              <option key={item._id} value={item._id}>
                                {t(item.name)}
                              </option>
                            );
                          })}
                        </Form.Select>
                      )}
                      {errors.subcategory && touched.subcategory && (
                        <Form.Text className="text-danger">
                          {errors.subcategory}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col> */}
                {/* </Row> */}
                {/* nft blockchain */}
                <Row className="align-items-end">
                  <Col md={7}>
                    <Form.Group>
                      <Form.Label className="create-section-label pt-3">
                        {t("create-nft.blockchain")}
                        <span className="mandatory">*</span>
                      </Form.Label>
                      <InfoTooltip text={t("create-nft.blockchain_tooltip")} />
                      <Form.Select
                        name="chain"
                        id="chain"
                        disabled={disabledField}
                        className="create-page-input-field"
                        onBlur={handleBlur}
                        value={chain}
                        errors={errors.chain}
                        onChange={(e) => {
                          dataload();
                          handleChange(e);
                          onChangeChain(e.target.value);
                        }}
                      >
                        <option value="ETH">{t("Ethereum")}</option>
                        <option value="MATIC">{t("Polygon")}</option>
                        {window.ENV_NAME !== "production" ? (
                          <option value="BOBA">{t("Boba-BNB")}</option>
                        ) : (
                          ""
                        )}
                        {/* {window.ENV_NAME !== "production" && (
                          <option value="BNB">{t("Binance")}</option>
                        )}
                        {window.ENV_NAME !== "production" && (
                          <option value={chain}>{t("Algorand")}</option>
                        )} */}
                      </Form.Select>
                      {errors.chain && touched.chain && (
                        <Form.Text className="text-danger">
                          {errors.chain}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  {!nft?._id && (
                    <Col className="mb-1 mt-md-0 mt-3">
                      <Button
                        disabled={disabledField}
                        onClick={() => setConnectWalletModal(true)}
                      >
                        {t(
                          another_wallet
                            ? "create-nft.disconnect_external_wallet"
                            : "create-nft.connect_external_wallet"
                        )}
                      </Button>
                    </Col>
                  )}
                </Row>

                <Form.Group>
                  <Form.Label className="create-section-label pt-3">
                    {t("create-nft.wallet")}
                    <span className="mandatory">*</span>
                  </Form.Label>
                  <InfoTooltip text={t("create-nft.wallet_tooltip")} />

                  <Form.Select
                    name="creator"
                    id="creator"
                    className="create-page-input-field"
                    onBlur={handleBlur}
                    value={values.creator}
                    errors={errors.creator}
                    style={
                      nft?._id
                        ? {
                            pointerEvents: "none",
                            cursor: "not-allowed",
                            opacity: "0.65",
                          }
                        : {}
                    }
                    onChange={(e) => {
                      handleChange(e);
                      setSelectWallet(e.target.value);
                    }}
                  >
                    <option value="">{t("create-nft.select_wallet")}</option>
                    {default_wallet && (
                      <option
                        value={default_wallet}
                        selected={
                          nft?.creators?.minter?.create_wallet ===
                          default_wallet
                        }
                      >
                        {t("nifty_wallet")}
                      </option>
                    )}
                    {another_wallet && (
                      <option
                        value={another_wallet}
                        selected={
                          nft?.creators?.minter?.create_wallet ===
                          another_wallet
                        }
                      >
                        {t("another_wallet")}
                      </option>
                    )}
                    {/* {window.ENV_NAME !== "production" && algo_wallet && (

                          <option value={algo_wallet}>{t("algo_wallet")}</option>
                        )} */}
                  </Form.Select>
                  {errors.creator && touched.creator && (
                    <Form.Text className="text-danger">
                      {errors.creator}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="create-section-label pt-3">
                    {t("create-nft.total_royalties")}
                  </Form.Label>
                  <InfoTooltip text={t("create-nft.total_royalties_tooltip")} />
                  <Form.Select
                    min={0}
                    max={30}
                    type="number"
                    id="totalRoyalty"
                    name="totalRoyalty"
                    className="create-page-input-field"
                    onBlur={handleBlur}
                    value={values.totalRoyalty}
                    touched={touched.totalRoyalty}
                    errors={errors.totalRoyalty}
                    onChange={handleChange}
                  >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30].map(
                      (v) => {
                        return <option value={v}>{v}%</option>;
                      }
                    )}
                  </Form.Select>
                  {errors.totalRoyalty && touched.totalRoyalty && (
                    <Form.Text className="text-danger">
                      {errors.totalRoyalty}
                    </Form.Text>
                  )}
                </Form.Group>
                {/* <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label className="create-section-label pt-3">
                        {t("create-nft.creator_royalty")}
                      </Form.Label>
                      <InfoTooltip text={t("create-nft.royalty_tooltip")} />
                      <Form.Select
                        min={0}
                        max={10}
                        name="royalty"
                        id="royalty"
                        type="number"
                        className="create-page-input-field"
                        onBlur={handleBlur}
                        value={values.royalty}
                        errors={errors.royalty}
                        onChange={handleChange}
                        disabled={values.totalRoyalty <= 0}
                      >
                        <option value={0}>0%</option>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => {
                          const final = sumRoyalties + v - values.royalty;
                          return (
                            <option value={v} disabled={final > maxRoyalty}>
                              {v}0%
                            </option>
                          );
                        })}
                      </Form.Select>
                      {errors.royalty && touched.royalty && (
                        <Form.Text className="text-danger">
                          {errors.royalty}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col> */}
                <div className="section-divider  my-5" />
                <Row>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="create-section-title">
                      {t("create-nft.add_partner")}
                      <InfoTooltip text={t("create-nft.add_partner_tooltip")} />
                    </div>
                    <Form.Check
                      type="switch"
                      name="isPartners"
                      value={values.isPartners}
                      checked={values.isPartners}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.checked) {
                          addFormFields();
                          setEditMode(true);
                        } else setFormValues([]);
                      }}
                    />
                  </div>
                  <p className="create-section-text">
                    {t("create-nft.partners_note")}
                  </p>
                  <fieldset disabled={!editMode} ref={partnersForm}>
                    {formValues &&
                      formValues.map((formValue, i) => (
                        <Row key={i.toString()}>
                          <Col xs={12} md={12} lg={5}>
                            <Form.Label className="create-section-label mt-2">
                              {t("create-nft.partner_address")}
                            </Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="creator"
                              id="creator"
                              className="create-page-input-field"
                              value={formValue.creator}
                              placeholder={t(
                                "create-nft.partner_address_placeholder"
                              )}
                              onBlur={onValidatePartners}
                              onChange={(e) => handelFormChange(e, i)}
                            />
                            {!partnersFormValid && !formValue.isValid && (
                              <Form.Text className="text-danger">
                                {formValue.matchCreator
                                  ? t("create-nft.partner_match_address_error")
                                  : t("create-nft.partner_address_error")}
                              </Form.Text>
                            )}
                          </Col>
                          <Col xs={5} md={5} lg={3}>
                            <Form.Label className="create-section-label mt-2">
                              {t("Royalty")}
                              <InfoTooltip
                                text={t("create-nft.partner_royalty_tooltip")}
                              />
                            </Form.Label>
                            <Form.Select
                              required
                              name="royalty"
                              id="royalty"
                              value={formValue.royalty}
                              className="create-page-input-field"
                              disabled={values.totalRoyalty <= 0}
                              onBlur={onValidatePartners}
                              onChange={(e) => handelFormChange(e, i)}
                            >
                              <option value={0}>0%</option>
                              {[0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => {
                                const final =
                                  sumRoyalties + v - formValue.royalty;
                                return (
                                  <option
                                    value={v}
                                    disabled={final > maxRoyalty}
                                  >
                                    {v * 10}%
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Col>
                          <Col xs={5} md={5} lg={3}>
                            <Form.Label className="create-section-label mt-2">
                              {t("Revenue")}
                              <InfoTooltip
                                text={t("create-nft.partner_revenue_tooltip")}
                              />
                            </Form.Label>
                            <Form.Select
                              name="revenue"
                              id="revenue"
                              value={formValue.revenue}
                              aria-label="Default select example"
                              className="create-page-input-field"
                              onBlur={onValidatePartners}
                              onChange={(e) => handelFormChange(e, i)}
                            >
                              <option value={0}>0%</option>
                              {[0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => {
                                const final =
                                  sumRevenue + v - formValue.revenue;
                                return (
                                  <option
                                    value={v}
                                    disabled={final > maxRevenue}
                                  >
                                    {v * 10}%
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Col>
                          {editMode && (
                            <Col
                              xs={2}
                              md={2}
                              lg={1}
                              className="cursor mt-5 pt-1"
                            >
                              {i == 0 ? (
                                <div onClick={addFormFields}>
                                  <img src={AddRowIcon} />
                                </div>
                              ) : (
                                <div onClick={() => removeFormFields(i)}>
                                  <img src={DeleteRowIcon} />
                                </div>
                              )}
                            </Col>
                          )}
                        </Row>
                      ))}
                  </fieldset>
                  {formValues?.length > 0 ? (
                    editMode ? (
                      <div className="d-flex flex-row align-items-center">
                        <div
                          style={{ width: "20%" }}
                          onClick={onSubmitPartners}
                          className="btn btn-primary mb-2 mt-4 cursor"
                        >
                          {t("Save")}
                        </div>
                        {/* <div
                          onClick={onCancelPartners}
                          style={{ width: "20%", marginInlineStart: 10 }}
                          className="btn btn-secondary-outline mb-2 mt-4 cursor"
                        >
                          {t("Cancel")}
                        </div> */}
                      </div>
                    ) : (
                      <div>
                        <button
                          onClick={() => setEditMode(!editMode)}
                          className="btn btn-primary mb-2 mt-4 cursor"
                        >
                          {t("edit")}
                        </button>
                      </div>
                    )
                  ) : null}
                </Row>
                <div className="section-divider my-5" />
                <div>
                  <div className="create-section-title">
                    {t("editions")}
                    <span className="mandatory">*</span>
                    <InfoTooltip text={t("create-nft.amount_tooltip")} />
                  </div>
                  <p className="create-section-text">
                    {t("create-nft.amount_note")}
                  </p>
                  <Form.Group className="mt-4">
                    <Form.Label className="create-section-label">
                      {t("create-nft.no_editions")}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        min={1}
                        // max={50000}
                        step={1}
                        name="amount"
                        id="amount"
                        type="number"
                        className="create-page-input-field"
                        onBlur={handleBlur}
                        value={values.amount}
                        errors={errors.amount}
                        onChange={handleChange}
                        touched={touched.amount}
                        disabled={disabledField}
                      />
                    </InputGroup>
                    {errors.amount && touched.amount && (
                      <Form.Text className="text-danger">
                        {errors.amount}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
                <div className="section-divider my-5" />
                <Row>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="create-section-title">
                      {t("List for Sale")}
                      <InfoTooltip
                        text={t("create-nft.list_for_sale_tooltip")}
                      />
                    </div>
                    <Form.Check
                      type="switch"
                      name="isList"
                      disabled={disabledField}
                      value={values.isList}
                      checked={values.isList}
                      onChange={handleChange}
                    />
                  </div>

                  {values.isList && (
                    <div className="price-select mt-4">
                      <Form.Group>
                        <Row>
                          <Col md={6} xs={6}>
                            <div className="d-flex flex-row align-items-center">
                              <Form.Check
                                type="radio"
                                name="listType"
                                value="fixedPrice"
                                label={t("Fixed Price")}
                                checked={values.listType === "fixedPrice"}
                                onChange={handleChange}
                                disabled={disabledField}
                              />
                              <InfoTooltip
                                text={t("create-nft.fixed_price_tooltip")}
                              />
                            </div>
                          </Col>
                          {values.amount <= 1 && chain !== "BOBA" && (
                            <Col md={6} xs={6}>
                              <div className="d-flex flex-row align-items-center">
                                <Form.Check
                                  type="radio"
                                  name="listType"
                                  value="auction"
                                  label={t("Auction")}
                                  checked={values.listType === "auction"}
                                  onChange={handleChange}
                                  disabled={disabledField}
                                />
                                <InfoTooltip
                                  text={t("create-nft.auction_tooltip")}
                                />
                              </div>
                            </Col>
                          )}
                        </Row>
                      </Form.Group>
                      {values.listType == "fixedPrice" && (
                        <Form.Group className="mt-4">
                          <Form.Label className="create-section-label">
                            {t("create-nft.piece_price")}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              min={0}
                              step={0.001}
                              name="price"
                              id="price"
                              type="number"
                              className="create-page-input-field"
                              onBlur={handleBlur}
                              value={values.price}
                              touched={touched.price}
                              errors={errors.price}
                              onChange={handleChange}
                              disabled={disabledField}
                              style={{ width: "65%" }}
                            />
                            <Form.Select
                              type="text"
                              placeholder={t("referral_code")}
                              className="create-page-input-field"
                              value={currency}
                              onChange={(e) => setCurrency(e.target.value)}
                              style={{
                                color: props.colors?.text,
                                backgroundColor: props.colors?.bg,
                              }}
                            >
                              {/* <option value={"USDC"}>{"USDC"}</option> */}
                              <option value={"currency"}>Select currency</option>
                              <option value={""}>{chain}</option>
                              <option value={"USDC"}>{"USDC"}</option>
                            </Form.Select>
                          </InputGroup>
                          {errors.price && touched.price && (
                            <Form.Text className="text-danger">
                              {errors.price}
                            </Form.Text>
                          )}
                        </Form.Group>
                      )}
                      {values.listType == "auction" && (
                        <div>
                          <Row className="mt-4">
                            <Col xs={12} md={6}>
                              <Form.Group>
                                <Form.Label className="create-section-label">
                                  {t("create-nft.min_price")}
                                  <InfoTooltip
                                    text={t("create-nft.start_price_tooltip")}
                                  />
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    min={0}
                                    step={0.001}
                                    type="number"
                                    name="startBidPrice"
                                    id="startBidPrice"
                                    className="create-page-input-field"
                                    onBlur={handleBlur}
                                    value={values.startBidPrice}
                                    touched={touched.startBidPrice}
                                    errors={errors.startBidPrice}
                                    onChange={handleChange}
                                    disabled={disabledField}
                                  />
                                  <InputGroup.Text>{chain}</InputGroup.Text>
                                </InputGroup>
                                {errors.startBidPrice &&
                                  touched.startBidPrice && (
                                    <Form.Text className="text-danger">
                                      {errors.startBidPrice}
                                    </Form.Text>
                                  )}
                              </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                              <Form.Group className="mb-4">
                                <Form.Label className="create-section-label">
                                  {t("create-nft.reserve_price")}
                                  <InfoTooltip
                                    text={t("create-nft.reserve_price_tooltip")}
                                  />
                                </Form.Label>
                                <InputGroup>
                                  <Form.Control
                                    type="number"
                                    min={0}
                                    step={0.001}
                                    name="reservePrice"
                                    id="reservePrice"
                                    className="create-page-input-field"
                                    onBlur={handleBlur}
                                    value={values.reservePrice}
                                    touched={touched.reservePrice}
                                    errors={errors.reservePrice}
                                    onChange={handleChange}
                                    disabled={disabledField}
                                  />
                                  <InputGroup.Text>{chain}</InputGroup.Text>
                                </InputGroup>
                                {errors.reservePrice &&
                                  touched.reservePrice && (
                                    <Form.Text className="text-danger">
                                      {errors.reservePrice}
                                    </Form.Text>
                                  )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={6}>
                              <Form.Group>
                                <Form.Label className="create-section-label">
                                  {t("create-nft.auction_period")}
                                  <InfoTooltip
                                    text={t(
                                      "create-nft.auction_period_tooltip"
                                    )}
                                  />
                                </Form.Label>
                                <Form.Select
                                  name="day"
                                  id="day"
                                  className="create-page-input-field"
                                  onBlur={handleBlur}
                                  value={values.day}
                                  touched={touched.day}
                                  errors={errors.day}
                                  onChange={handleChange}
                                  disabled={disabledField}
                                >
                                  {window.ENV_NAME !== "production" && (
                                    <option value={0.01}>13 {t("min")}</option>
                                  )}
                                  <option value={1}>1 {t("Day")}</option>
                                  <option value={3}>3 {t("Days")}</option>
                                  <option value={5}>5 {t("Days")}</option>
                                  <option value={7}>7 {t("Days")}</option>
                                </Form.Select>
                                {errors.day && touched.day && (
                                  <Form.Text className="text-danger">
                                    {errors.day}
                                  </Form.Text>
                                )}
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  )}
                </Row>
                {/* switch Choose Collection*/}
                {!collectionId && (
                  <div>
                    <div className="section-divider my-5" />
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="create-section-title">
                        {t("create-nft.choose_collection")}
                        <InfoTooltip
                          text={t("create-nft.choose_collection_tooltip")}
                        />
                      </div>
                      <Form.Check
                        type="switch"
                        name="isCollection"
                        id="isCollection"
                        value={values.isCollection}
                        checked={values.isCollection}
                        onChange={handleChange}
                        disabled={disabledField}
                      />
                    </div>
                    <p className="create-section-text">
                      {t("create-nft.choose_collection_note")}
                    </p>
                    {values.isCollection && (
                      <Row>
                        <Col lg={8} md={5} xs={12}>
                          <ChooseCollection
                            disabled={disabledField}
                            label={t("create-nft.select_collection")}
                            items={collections.map((item) => ({
                              _id: item._id,
                              title: item.title,
                              logo: item.logo,
                            }))}
                            onSelect={(selectedItem) => {
                              const collectionItem = collections.find(
                                (item) => item._id === selectedItem._id
                              );
                              setFieldValue("collection", collectionItem);
                            }}
                            selectedCollection={values.collection}
                          />
                        </Col>
                        <Col lg={4} xs={12} md={2}>
                          <div
                            disabled
                            className="create-collection-item"
                            onClick={() =>
                              !disabledField && setOpenCreateCollection(true)
                            }
                          >
                            <img src={addIcon} width={32} height={32} alt="" />
                            <p className="modal-section-title iconP">
                              {t("create-nft.create_collection")}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                )}
                {/* switch Lazy mint*/}
                <div>
                  <div className="section-divider my-5" />

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="create-section-title">
                      {t("create-nft.lazy_mint")}
                      <InfoTooltip text={t("create-nft.lazy_mint_tooltip")} />
                    </div>
                    <Form.Check
                      type="switch"
                      name="isLazymint"
                      id="isLazymint"
                      style={
                        nft?._id
                          ? {
                              pointerEvents: "none",
                              cursor: "not-allowed",
                              opacity: "0.65",
                            }
                          : {}
                      }
                      value={values.isLazymint}
                      checked={values.isLazymint}
                      onChange={handleChange}
                      disabled={disabledField}
                    />
                  </div>
                  <p className="create-section-text">
                    {t("create-nft.lazy_mint_note")}
                  </p>
                </div>
                {/* switch payment*/}
                {!values.isLazymint && (
                  <div>
                    <div className="section-divider my-5" />
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="create-section-title">
                        {t("create-nft.payment")}
                        <span className="mandatory">*</span>
                        <InfoTooltip text={t("create-nft.payment_tooltip")} />
                      </div>
                    </div>
                    <p className="create-section-text">
                      {t("create-nft.payment_note")}
                    </p>
                    <Form.Group>
                      <Row>
                        <Col xs={6} md={4}>
                          <div className="d-flex flex-row align-items-center">
                            <Form.Check
                              type="radio"
                              name="paymentType"
                              value="wallet"
                              label={t("create-nft.wallet")}
                              checked={values.paymentType === "wallet"}
                              onChange={handleChange}
                              disabled={disabledField}
                            />
                            <InfoTooltip
                              text={t("create-nft.wallet_payment_tooltip")}
                            />
                          </div>
                        </Col>
                        {/* {chain != "MATIC" && ( */}
                        {/* {chain !== "BOBA" && (
                          <Col xs={6} md={6}>
                            <div className="d-flex flex-row align-items-center">
                              <Form.Check
                                type="radio"
                                name="paymentType"
                                value="card"
                                label={t("create-nft.debit_credit_card")}
                                checked={values.paymentType === "card"}
                                onChange={handleChange}
                                disabled={disabledField}
                              />
                              <InfoTooltip
                                text={t("create-nft.card_payment_tooltip")}
                              />
                            </div>
                          </Col>
                        )} */}
                        {/* )} */}
                      </Row>
                    </Form.Group>
                  </div>
                )}
                <div className="section-divider my-5" />
                <div className="d-flex flex-column flex-md-row justify-content-start mt-5 pt-5">
                  <Button
                    type="submit"
                    className="mb-2 mb-md-0"
                    disabled={
                      (values.isPartners && editMode) ||
                      isSubmitting ||
                      action_data?.loading
                    }
                  >
                    {nft?._id
                      ? t("create-nft.update_nft")
                      : t("create-nft.create_nft")}
                    {action_data?.loading ? (
                      <span className="mx-1">
                        <Spinner animation="border" color="#fff" size="sm" />
                      </span>
                    ) : (
                      <img src={arrowRight} alt="arrow right" />
                    )}
                  </Button>
                  {/* <Button
                    onClick={() => navigate(-1)}
                    style={{ marginInlineStart: 20 }}
                    className="mb-2 mb-md-0  modal-outline-button"
                  >
                    {t("cancel")}
                  </Button> */}
                </div>
              </Form>
            </Col>
            {/* preview */}
            <Col
              md={5}
              style={{ marginTop: 150 }}
              className="d-none d-md-flex justify-content-center"
            >
              <div>
                {values.nft?.file_reader ? (
                  <div style={{ position: "relative" }}>
                    <PreviewNftCard
                      item={{
                        creator_image_url:
                          auth_account?.account?.profile?.image_url,
                        creator_name: auth_account?.account?.name,
                        url: values.nft?.file_reader,
                        type: values.nft?.file_type,
                        name: values.name,
                        // sale_type: saleType,
                        price: values.price || 0,
                        day: values.day,
                      }}
                    />
                    <div
                      className="cursor expand-button"
                      onClick={() => setImageModal(true)}
                    >
                      <Expand color="white" />
                    </div>
                  </div>
                ) : (
                  <img
                    style={{ width: 356, height: 251, objectFit: "contain" }}
                    src={require("@assets/images/nft-preview.png")}
                  />
                )}
              </div>
            </Col>

            {isFiatModal && (
              <div>
                <ModalAndOverlay>
                  <div className="d-flex justify-content-between mb-4">
                    <h3 className="modal-heading">{t("create_with_card")}</h3>
                    <img
                      src={closeIcon}
                      alt="close icon"
                      className="modal-close-icon"
                      onClick={() => {
                        setFiatPayModal(false);
                        setStep(0);
                      }}
                    />
                  </div>
                  <div id="wert-widget"></div>
                  {step === 0 ? (
                    <div>
                      <p>{t("buy_continue_notice")}</p>
                      <Button
                        className="modal-button modal-outline-button"
                        onClick={() => {
                          createTokenFiat(
                            chain,
                            selectedWallet,
                            setWertPaymentStatus
                          );
                          setStep(1);
                        }}
                      >
                        {t("Continue")}
                        <img src={arrowRight} alt="arrow right" />
                      </Button>
                    </div>
                  ) : 0.05 <= parseInt(walletBalance) ? (
                    <Button
                      className="modal-button modal-outline-button"
                      onClick={() => {
                        createNFTToken();
                        setFiatPayModal(false);
                        setStep(0);
                      }}
                    >
                      {t("Proceed")}
                    </Button>
                  ) : (
                    <Button
                      className={`modal-button ${props?.colors?.button}`}
                      disabled={true}
                    >
                      {t("We are depositting your crypto, please wait...")}
                    </Button>
                  )}
                </ModalAndOverlay>
              </div>
            )}
          </Row>
        </Container>
      </StylesWrapper>
      {/* for connecting wallet (account)*/}
      <WalletModal isOpen={isOpenDialog} setIsOpen={setOpenDialog} />
      <CreateCollectionPopup
        categories={categories}
        isOpen={isOpenCreateCollection}
        setIsOpen={setOpenCreateCollection}
        creatorId={auth_account?.account?._id}
      />
      <ImageModal
        isOpen={imageModal}
        setIsOpen={setImageModal}
        nft={{ url: values.nft?.file_reader, type: values.nft?.file_type }}
      />
    </div>
  );
};
