import styled from "styled-components";
import {
  Form,
  Button,
  Row,
  Col,
  FormControl,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import arrowRight from "../../icons/arrow-right.svg";
import TopUp from "../../assets/icons/top-up-big.svg";
// import dollar from "../../assets/icons/dollar-green.svg";
// import eth from "../../assets/icons/eth.svg";
import switchIcon from "../../assets/icons/switch.svg";
// import matic from "../../assets/icons/matic.svg";
import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import WertWidget from "@wert-io/widget-initializer";
import { v4 as uuidv4 } from "uuid";
import { useWeb3Auth } from "../../context/Web3auth";
import { useSelector } from "react-redux";
import { chains } from "@config";
import keys from "../../lib/config";
import { InfoTooltip } from "@components";
const Wrapper = styled.div`
  .custom-dropdown {
    max-width: 465px;
    margin-bottom: 36px;
  }
  .info-section {
    max-width: 465px;
    .dots {
      border-bottom: 1px dashed #b4b4b4;
    }
    .amount {
      font-weight: 500;
    }
  }
  .calculate {
    margin-bottom: 72px;
    max-width: 465px;
  }
  .calculate-input {
    color: #6d3190;
    font-size: 15px;
    caret-color: black;
  }
  .calculate-input-section {
    position: relative;
    span {
      position: absolute;
      top: 8px;
      right: 12px;
      font-size: 20px;
      right: ${(props) => (props.isLTR ? "12px" : "unset")};
      left: ${(props) => (props.isLTR ? "unset" : "12px")};
    }
  }
  .switch-container {
    width: 40px;
    height: 40px;
    background: #f4f5f6;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .currency-span {
    font-size: 20px;
  }
`;

const Transfer = () => {
  const [amount, setAmount] = useState("0");
  const [address, setAddress] = useState("");
  const [crypto, setCrypto] = useState("ETH");
  const [currency, setCurrency] = useState("USD");
  const { t, i18n } = useTranslation();
  const [defaultWallet, setDefaultWallet] = useState("");
  const [walletOptions, setWalletOptions] = useState([]);
  const [selectedWallet, setSelectWallet] = useState("");
  const { default_wallet, another_wallet } = useSelector(
    (state) => state.wallets
  );
  const [otherWallet, setOtherWallet] = useState(false);
  const { getAccounts, chain, privateKey } = useWeb3Auth();
  const { chainId, account } = useWeb3React();
  const isLTR = i18n.language !== "ar";
  const options = {
    partner_id: window.WERT_PARTNER_ID,
    container_id: "wert-widget",
    click_id: uuidv4(), // unique id of purhase in your system
    width: 500,
    height: 700,
    currency: currency,
    commodity: crypto,
    commodities: "BTC,ETH,MATIC,USDC,TT",
    currency_amount: amount,
    address: address,
    origin: window.WERT_ORIGIN,
    listeners: {
      loaded: () => console.log("loaded"),
    },
  };
  const wertWidget = new WertWidget(options);

  // const dataload = async () => {
  //   //get default wallet
  //   const wallet = getAccounts();
  //   setSelectWallet(wallet.address);
  //   setDefaultWallet(wallet.address);
  //   const wallets = [];
  //   wallets.push({ value: wallet.address, label: wallet.address });
  //   if (account) {
  //     if (chains[chain].chainId === chainId) {
  //       wallets.push({ value: account, label: account });
  //     }
  //   }
  //   setWalletOptions(wallets);
  //   console.log(wallets, "wallet options");
  // };

  // useEffect(() => {
  //   dataload();
  // }, [privateKey, account, chain]);

  return (
    <Wrapper isLTR={isLTR}>
      <Row className="border-bottom-1 justify-content-between">
        <Col>
          <div className="page-header-md">
            {t("Top up")}
            <InfoTooltip text={t("wallet.top_up_tooltip")} />
          </div>
        </Col>
        <Col className="d-none d-md-block">
          <div className="d-flex justify-content-end">
            <img src={TopUp} alt="" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {/* calculate */}
          <div className="mt-4 calculate">
            <div className="d-flex justify-content-center align-items-center mt-3">
              <div
                className="d-flex align-items-center me-3"
                style={{ paddingTop: 10 }}
              >
                <DropdownButton
                  title={t(currency)}
                  onSelect={(e) => setCurrency(e)}
                >
                  <Dropdown.Item eventKey="USD">{t("USD")}</Dropdown.Item>
                  {/* <Dropdown.Item eventKey="EUR">EUR</Dropdown.Item> */}
                </DropdownButton>
              </div>
              <div className="me-3 switch-container">
                <img src={switchIcon} alt="" />
              </div>
              <div
                className="d-flex align-items-center"
                style={{ paddingTop: 10 }}
              >
                <DropdownButton
                  id="dropdown-menu-align-end"
                  title={t(crypto)}
                  onSelect={(e) => setCrypto(e)}
                >
                  <Dropdown.Item value={"ETH"} eventKey="ETH">{t("ETH")}</Dropdown.Item>
                  <Dropdown.Item value={"MATIC"} eventKey="MATIC">{t("Matic")}</Dropdown.Item>
                  <Dropdown.Item value={"TT"} eventKey="USDC">{t("USDC")}</Dropdown.Item>
                </DropdownButton>
              </div>
            </div>

            {/* form */}

            {/* recipient wallet address */}
            <Col md={4}>
              <Form.Label
                className="payment-page-label"
                style={{ paddingTop: "30px" }}
              >
                {t("Amount")}
              </Form.Label>
              <div className="calculate-input-section">
                <FormControl
                  type="text"
                  className="payment-page-input width-100 calculate-input"
                  onChange={(e) => setAmount(e.target.value)}
                  value={amount}
                  placeholder={t("Amount")}
                />
                <span>$</span>
              </div>
            </Col>
            <Form.Label
              className="payment-page-label"
              style={{ paddingTop: "30px" }}
            >
              {t("Wallet address")}
            </Form.Label>

            <Form.Select
              aria-label="Wallet address"
              className="payment-page-input select"
              value={address}
              onChange={(e) => {
                {
                  e.target.value === "Other"
                    ? setOtherWallet(true)
                    : setOtherWallet(false);
                }
                setAddress(e.target.value);
              }}
            >
              <option>{t("Select wallet")}</option>
              {default_wallet && (
                <option value={default_wallet}>{default_wallet}</option>
              )}
              {another_wallet && (
                <option value={another_wallet}>{another_wallet}</option>
              )}
              <option>{t("Other")}</option>
            </Form.Select>

            {otherWallet && (
              <Form.Control
                className="payment-page-input"
                style={{ marginTop: "30px" }}
                type="text"
                placeholder="Insert another wallet address"
                // value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            )}

            {/* button */}
            <Button
              style={{ marginTop: "20px" }}
              onClick={() => wertWidget.mount()}
            >
              {t("Top up")}
              <img src={arrowRight} alt="arrow right" />
            </Button>
          </div>
        </Col>
        <Col>
          <div id="wert-widget"></div>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Transfer;
