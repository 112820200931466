import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { truncate } from "../core/string";
import { intervalToDuration } from "date-fns";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
// import mark from "@assets/icons/mark.svg";
import { ReactComponent as Mark } from "@assets/icons/mark.svg";
import moment from "moment";

const Wrapper = styled.div`
  .card {
    positions: relative;
    min-width: 291px;
    max-width: 291px;
    // margin-right: 15px;
    // margin-left: 10px;
    border: 1px solid #efefef;
    border-radius: 13.8258px;
    filter: drop-shadow(0.5rem 0.5rem 1rem #efefef);
  }
  .image {
    object-fit: cover;
  }
  .radius {
    border-radius: 9.2px;
  }
  .creator {
    color: #000;
    font-size: 14px;
    font-weight: 450;
  }
  .tag {
    color: #bdbdbd;
    font-size: 12px;
    font-weight: 400;
  }
  .name {
    font-size: 20px;
    color: #1c0039;
  }
  .divider {
    width: 100%;
    height: 0.66px;
    background-xolor: #e5e4de;
  }
  .label {
    color: #afa9bd;
    font-size: 12px;
  }
  .note {
    color: #afa9bd;
    height: 100%;
    font-size: 14px;
  }
  .note-text {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .value {
    color: #1c0039;
    font-size: 14px;
    font-weight: 400;
  }
  .card:hover {
    .overlay {
      visibility: visible;
      opacity: 1;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    min-width: 291px;
    border-radius: 13.8258px;
    z-index: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.25s linear;
  }
  .video {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 264px;
    max-height: 256px;
    min-width: 264px;
    min-height: 256px;
  }
  .marker {
    position: absolute;
    top: 10px;
    right: ${(props) => (props?.isLTR ? "0px" : "unset")};
    left: ${(props) => (props?.isLTR ? "unset" : "0px")};
  }
  .marker-txt {
    position: absolute;
    right: ${(props) => (props?.isLTR ? "13px" : "unset")};
    left: ${(props) => (props?.isLTR ? "unset" : "13px")};
    top: 17px;
    color: #fff;
    font-size: 9px;
    font-weight: 400;
    z-index: 2;
  }
  .icon {
    transform: ${(props) => (props?.isLTR ? "rotate(0deg)" : "rotate(180deg)")};
  }
`;

export const NftCard = ({
  item,
  userData,
  creator,
  isProject,
  lableSold = true,
}) => {
  const { account } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const [metaData, setMetaData] = useState("");

  const [duration, setDuration] = useState(null);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    let startTimerVar = null;
    function startTimer() {
      if (item?.listing?.sale_type === "2" && item?.listing?.auction_end_date) {
        if (new Date(item?.listing?.auction_end_date.toString()) > Date.now()) {
          startTimerVar = setInterval(() => {
            let dur = intervalToDuration({
              start: new Date(),
              end: new Date(item?.listing?.auction_end_date),
            });
            setDuration(dur);
          }, 1000);
        }
      }
      return {
        if(startTimerVar) {
          clearInterval(startTimerVar);
        },
      };
    }
    startTimer();
  }, [item?.listing]);
  useEffect(() => {
    if (item.name) {
      const metaDataTest = item?.contract_type
        ? JSON.parse(item.metadata)
        : item.metadata;
      setMetaData(metaDataTest);
    }
  }, [item]);

  const canBuy = item?.creators?.minter?._id
    ? account?._id != item?.creators?.minter?._id
    : account?._id != user?._id;
  const isLTR = i18n.language != "ar";
  // const [nftImage, setNftImage] = useState(() => {
  //   if (metaData.image) {
  //     if (metaData.image?.includes("ipfs:")) {
  //       return metaData.image.includes("ipfs")
  //         ? `https://ipfs.io/ipfs/${metaData.image.split("ipfs://")[1]}`
  //         : item?.metadata?.image.split("\\")[0];
  //     } else {
  //       return metaData.image;
  //     }
  //   }
  // });
  return (
    <Wrapper className="d-flex d-md-block justify-content-center" isLTR={isLTR}>
      <div
        className="p-3 pb-2 card"
        style={{ position: "relative" }}
        key={item?._id}
      >
        {item?.status == "PURCHASE" && lableSold && (
          <>
            <div className="marker">
              <Mark className="icon" />
            </div>
            <div className="marker-txt uppercase">{t("Sold")}</div>
          </>
        )}
        <Link
          to={`/profile/${
            metaData?.image
              ? account?._id
              : lableSold
              ? userData
                ? userData?._id
                : item?.creators?.minter
                ? item?.creators?.minter?._id
                : user?._id
              : user?._id
          }`}
          style={
            userData
              ? { zIndex: "0", textDecoration: "none" }
              : { zIndex: "13", textDecoration: "none" }
          }
        >
          <span className="d-flex">
            <span>
              <img
                src={
                  (lableSold
                    ? userData?.img
                      ? userData?.img
                      : item?.creators?.minter
                      ? item?.creators?.minter?.profile?.image_url
                      : user?.profile?.image_url
                    : item?.creators?.minter?.profile?.image_url) ||
                  "/user-placeholder.png"
                }
                className="user-30-rounded image me-3"
                alt=""
              />
            </span>
            <span style={{ lineHeight: "1" }}>
              <span className="creator d-block">
                {lableSold
                  ? userData
                    ? truncate(userData?.name, 30)
                    : item?.creators?.minter
                    ? truncate(item?.creators?.minter?.name, 30)
                    : user?.name
                  : item?.creators?.minter?.name ||
                    (item?.is_bezel && "The Bezel Club")}
              </span>
              <span className="tag">
                {creator ? `${t("By")}  ${creator?.name}` : t("Artist")}
              </span>
            </span>
          </span>
        </Link>
        <Link
          className="d-flex justify-content-center"
          to={`/nft/${item?.token_standard}/${item?._id}`}
        >
          <div
            className="my-3"
            style={{
              maxWidth: "264px",
              maxHeight: "256px",
              minWidth: "264px",
              minHeight: "256px",
              position: "relative",
            }}
          >
            {metaData?.image && (
              <img
                src={
                  metaData.image
                    ? metaData.image?.includes("ipfs:")
                      ? `https://ipfs.io/ipfs/${
                          metaData.image.split("ipfs://")[1]
                        }`
                      : metaData.image.split("\\")[0]
                    : metaData.image
                }
                alt=""
                className="image radius"
                style={{
                  maxWidth: "264px",
                  maxHeight: "256px",
                  minWidth: "264px",
                  minHeight: "256px",
                  objectFit: "cover",
                }}
              />
            )}

            {item?.type === "image" ? (
              <img
                alt=""
                src={metaData?.image ? metaData?.image : item?.url}
                className="image radius"
                style={{
                  maxWidth: "264px",
                  maxHeight: "256px",
                  minWidth: "264px",
                  minHeight: "256px",
                  objectFit: "cover",
                }}
              />
            ) : item?.type === "video" ? (
              <div className="video">
                <video
                  loop
                  muted
                  autoPlay
                  style={{
                    maxWidth: "264px",
                    maxHeight: "256px",
                    minWidth: "264px",
                    minHeight: "256px",
                  }}
                  className="image radius"
                  playsInline
                >
                  <source src={item?.url} type="video/mp4" />
                </video>
              </div>
            ) : (
              item?.type === "audio" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "264px",
                    maxHeight: "256px",
                    minWidth: "264px",
                    minHeight: "256px",
                  }}
                >
                  <audio className=" image radius" controls>
                    <source src={item?.url} type="audio/mp3" />
                  </audio>
                </div>
              )
            )}
          </div>
        </Link>
        <div className="d-flex justify-content-between align-items-center">
          {console.log("test", item)}
          <Link
            to={
              isProject
                ? `/nft/partner/${item?.contract}/${item?.token_id}`
                : item?.is_bezel
                ? `/club/${item?.contract}/${item?._id}`
                : item?.metadata
                ? `/linked/${
                    item?.metadata?.name
                      ? item?.contractType
                      : item?.contract_type
                  }/${item?.metadata?.name ? item?.tokenId : item?.token_id}`
                : `/nft/${item?.token_standard}/${item?._id}`
            }
            style={{ textDecoration: "none", zIndex: "10" }}
          >
            <div className="name">
              {item.metadata
                ? item.metadata.name
                  ? truncate(item.metadata?.name, 30)
                  : JSON.parse(item.metadata)?.name
                : truncate(item?.name, 30)}
            </div>
          </Link>
          {/* <Favorite width="16" id={item?._id} /> */}
        </div>

        <div className="my-2 divider" />
        <Link
          className="text-decoration-none"
          to={
            isProject
              ? `/nft/partner/${item?.contract}/${item?.token_id}`
              : item?.is_bezel
              ? `/club/${item?.contract}/${item?._id}`
              : item?.token_standard
              ? `/nft/${item?.token_standard}/${item?._id}`
              : item?.metadata
              ? `/linked/${
                  item?.metadata?.name
                    ? item?.contractType
                    : item?.contract_type
                }/${item?.metadata?.name ? item?.tokenId : item?.token_id}`
              : `/nft/${item?.token_standard}/${item?._id}`
          }
        >
          {item?.status == "PURCHASE" ? (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="label">{t("sold_price")}</div>
                <div className="value">
                  {item?.listing?.sold_price || item?.listing?.price}{" "}
                  {item?.listing?.currency || item?.blockchain}
                </div>
              </div>
            </div>
          ) : item?.listing?.sale_type == "0" ||
            item?.status == "CANCEL" ||
            item?.status == "UNLISTED" ? (
            <div className="d-flex justify-content-start align-items-center note">
              <div className="note-text">
                {isProject ? t("make_an_offer") : t("Not for Sale")}
              </div>
            </div>
          ) : item?.listing?.sale_type == "1" ? (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="label">{t("Fixed Price")}</div>
                <div className="value">
                  {item?.listing?.price}{" "}
                  {item?.listing?.currency || item?.blockchain}
                </div>
              </div>
            </div>
          ) : item?.listing?.sale_type == "2" ? (
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <div className="label">{t("Reserve Price")}</div>
                <div className="value">
                  {item?.listing?.price}{" "}
                  {item?.listing?.currency || item?.blockchain}
                </div>
              </div>
              {moment(new Date(item?.listing?.auction_end_date)).isAfter(
                new Date()
              ) ? (
                <div>
                  <div className="label">{t("Auction Ends in")}</div>
                  <span className="mx-1 value">
                    {duration?.days || "00"}
                    <span className="value">{t("d")}</span>
                  </span>
                  <span className="mx-1 value">
                    {duration?.hours || "00"}
                    <span className="value">{t("h")}</span>
                  </span>
                  <span className="mx-1 value">
                    {duration?.minutes || "00"}
                    <span className="value">{t("m")}</span>
                  </span>
                  {/* <span className="mx-1 value">
                {duration?.seconds || "00"}
                <span className="value">{t("s")}</span>
              </span> */}
                </div>
              ) : (
                <div>
                  <div className="label">{t("Auction Ended")}</div>
                  <span className="mx-1 value">
                    {moment(new Date(item?.listing?.auction_end_date))
                      .locale(i18n.language)
                      .fromNow()}
                  </span>
                </div>
              )}
            </div>
          ) : null}
          <div className="overlay d-flex flex-column justify-content-center align-items-center">
            {/* {canBuy && item?.listing?.sale_type == "1" ? (
            <div>
              <Link
                className="btn btn-primary gradient"
                to={isProject? `/nft/partner/${item?.contract}/${item?.token_id}` : `/nft/${item?.token_standard}/${item._id}`}
              >
                {t("Buy Now")}
              </Link>
            </div>
          ) : canBuy && item?.listing?.sale_type == "2" ? (
            <div>
              <Link
                className="btn btn-primary gradient"
                to={isProject? `/nft/partner/${item?.contract}/${item?.token_id}` : `/nft/${item?.token_standard}/${item?._id}`}
              >
                {t("Place a Bid")}
              </Link>
            </div>
          ) : null} */}
          </div>
        </Link>
      </div>
    </Wrapper>
  );
};
