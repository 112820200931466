/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import { Button, Form, Image, Spinner, Row, Col } from "react-bootstrap";
import Web3 from "web3";
import { chains, NIFTYSouq1155, NIFTYSouq721 } from "../config";
import { useWeb3Context } from "@context/Web3";
import { useWeb3Auth } from "@context/Web3auth";
//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  ethActionsCreators,
  nftActionsCreators,
  partnerActionCreators,
  stripeActionsCreators,
  wertActionsCreators,
} from "../store";
import { useTranslation } from "react-i18next";
import { getEditions, extractLoggedInOwner } from "../core/nft";
//icons
import completedIcon from "@assets/images/icons/completed.svg";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
import lockOrderIcon from "@assets/images/icons/lock-order-circle.svg";
import visamaster from "@assets/icons/visa-master.png";
//Checkout modal
import ModalAndOverlay from "./ModalAndOverlay";
import WalletModal from "./WalletModal";
import { Checkout } from "./Modals";
import WertWidget from "@wert-io/widget-initializer";
import { v4 as uuidv4 } from "uuid";
import keys from "../lib/config";
import Swal from "sweetalert2";
import Countdown from "react-countdown";

//stripe
import StripeCheckoutForm from "./StripeCheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getNetworkFromCurrency } from "../core/services/nft";
import WalletDropDownList from "./WalletDropDownList";
import { signSmartContractData } from "@wert-io/widget-sc-signer";
import { Buffer } from "buffer/";

window.Buffer = Buffer;
const stripeKey = loadStripe(window.STRIPE_PUBLISH_KEY);

const BuyOfferModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  console.log(props);
  const { nft, externalNFT, selectedOwner, closeOfferModal } = props;
  const is1155 = nft?.token_standard === "1155";
  const isBezelClub = externalNFT?.club === "TheBezelClub";
  const price = is1155 ? selectedOwner?.price : nft?.listing?.price;
  const owner = nft?.owners?.find((e) => e.user?._id === selectedOwner._id);

  //creators
  const { purchaseNFTCreator } = bindActionCreators(
    nftActionsCreators,
    dispatch
  );
  const { purchasePartnerNFT } = bindActionCreators(
    partnerActionCreators,
    dispatch
  );

  const { getSignatureCreator } = bindActionCreators(
    ethActionsCreators,
    dispatch
  );
  //store state
  const eth = useSelector((state) => state.eth);
  const auth_account = useSelector((state) => state.auth);
  const { signature } = useSelector((state) => state.eth);

  //stripe
  const { getClientKey } = bindActionCreators(stripeActionsCreators, dispatch);
  const { data, error: stripeError } = useSelector((state) => state.stripe);
  const [clientSecret, setClientSecret] = useState("");
  //bezel-club
  const [paymentType, setPaymentType] = useState("card");
  const [walletType, setWalletType] = useState("nifty");

  //context
  const { chain } = useWeb3Auth();
  const { buyOffer, buyOfferUSDT, lazyMintToken } = useWeb3Context();
  //state
  const [error, setError] = useState("");
  const [status, setStatus] = useState(0); //0 //1 loading //2 success //3 error
  const [code, setCode] = useState("");
  const [amount, setAmount] = useState(1);
  const [currency, setCurrency] = useState("");
  const [isFiat, setFiatPay] = useState(false);
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  const [selectedWallet, setSelectWallet] = useState(
    props.walletOptions[1]?.value
  );
  //wert
  const { getWertOrder } = bindActionCreators(wertActionsCreators, dispatch);
  const { checkBackendStatus } = bindActionCreators(
    wertActionsCreators,
    dispatch
  );
  const callApi = (body) => {
    checkBackendStatus(body);
  };
  const [wertStatus, setWertStatus] = useState(4);
  const [txHash, setTx] = useState("");
  const [wertPaymentStatus, setWertPaymentStatus] = useState("pending");
  const [wertPosition, setPosition] = useState("");
  const [gasFees, setGasFees] = useState(0);

  const { default_wallet, another_wallet } = useSelector(
    (state) => state.wallets
  );
  const options = {
    partner_id: window.WERT_PARTNER_ID,
    container_id: "wert-widget",
    click_id: uuidv4(), // unique id of purhase in your system
    commodity:
      nft?.listing?.currency === "USDC"
        ? chain === "ETH"
          ? "USDC:ethereum"
          : "USDC:polygon"
        : chain,
    width: 400,
    height: 500,
    origin: window.WERT_ORIGIN,
    listeners: {
      "payment-status": data => {
        setWertPaymentStatus(data.status);
        setTx(data.tx_id);
        console.log({ status: data.status, tx_id: data.tx_id });
      },
      position: res => {
        console.log(res.step);
        setPosition(res.step);
      },
      error: ({ message = "" }) => {
        console.log(message);
      },
    },
    extra: {
      item_info: {
        author_image_url: nft?.creators?.minter?.profile?.image_url,
        author: nft?.creators?.minter?.name,
        image_url: nft?.url,
      },
    },
  };

  // const [walletBalance, setWalletBalance] = useState(0);
  // const [salePrice, setSalePrice] = useState("");

  useEffect(() => {
    startLazyMint();
  }, [signature?.signature]);

  //stripe
  useEffect(() => {
    if (stripeError) setFiatPay(false);
    else if (data?.clientSecret) setClientSecret(data.clientSecret);
  }, [data, stripeError]);

  const closeModal = (cls) => closeOfferModal(cls);

  const buy = async (isFiat) => {
    console.log("start buying");
    try {
      if (isFiat) {
        setStatus(1);

        const web3 = new Web3(
          new Web3.providers.HttpProvider(chains[chain].rpcTarget)
        );

        const sc_input_data_buy = web3.eth.abi.encodeFunctionCall(
          {
            inputs: [
              {
                internalType: "uint256",
                name: "offerId_",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity_",
                type: "uint256",
              },
              {
                internalType: "address",
                name: "buyer_",
                type: "address",
              },
            ],
            name: "buyNft",
            outputs: [],
            stateMutability: "payable",
            type: "function",
          },
          [
            nft?.token_standard === "1155"
              ? selectedOwner?.offer_id
              : nft?.offer_id,
            amount,
            selectedWallet,
          ]
        );

        console.log(
          { sc_input_data_buy },
          amount,
          nft?.token_standard === "1155"
            ? selectedOwner?.offer_id
            : nft?.offer_id
        );
        const signedData = signSmartContractData(
          {
            commodity: nft?.listing?.currency === "USDC" ? "USDC" : chain,
            commodity_amount: (price * 1.025 * amount).toFixed(3),
            address: selectedWallet,
            pk_id: "key1",
            sc_address: window.ADDRESSES.FIXED[chain],
            sc_id: uuidv4(),
            sc_input_data: sc_input_data_buy,
          },
          window.WERT_PK
        );
        const wertWidget = new WertWidget({ ...signedData, ...options });
        wertWidget.setTheme({
          theme: "dark",
          colors: {
            color_buttons: "white",
          },
        });
        wertWidget.mount();
        console.log(wertPaymentStatus);
      } else {
        setStatus(1);
        const price = (
          nft?.token_standard === "1155"
            ? (
                (selectedOwner?.price + selectedOwner?.price * 0.025) *
                amount
              ).toFixed(6)
            : (
                (nft?.listing?.price + nft?.listing?.price * 0.025) *
                amount
              ).toFixed(6)
        ).toString();
        const res =
          nft?.listing?.currency === "USDC"
            ? await buyOfferUSDT(
                nft?.token_standard === "1155"
                  ? selectedOwner?.offer_id
                  : nft?.offer_id,
                amount,
                price,
                selectedWallet,
                selectedWallet === props.defaultWallet
              )
            : await buyOffer(
                nft?.token_standard === "1155"
                  ? selectedOwner?.offer_id
                  : nft?.offer_id,
                amount,
                price,
                selectedWallet,
                selectedWallet === props.defaultWallet
              );
        if (res.status === false) {
          Swal.fire({
            icone: "error",
            title: t("oops"),
            text: res.data.message,
            color: props?.colors?.text,
            background: props?.colors?.bg,
            confirmButtonText: t("Ok"),
            confirmButtonColor: "#6d3190",
          });
        }
        // save to backend
        if (res.status) {
          let buyEditions = [];

          if (is1155) {
            const user = await extractLoggedInOwner(
              selectedOwner?.user?._id,
              nft?.owners
            );
            buyEditions = await getEditions(user?.listed_editions, amount);
          }

          let buyData = {
            price,
            wallet: selectedWallet,
            tx: res.data.transactionHash,
            token_id: nft?.token_id,
            owner:
              nft?.token_standard === "1155"
                ? selectedOwner.user?.create_wallet
                : nft?.owner?.create_wallet,
            editions: nft?.token_standard === "1155" ? buyEditions : [],
            currency: isBezelClub ? currency : chain,
            is_lazy: nft?.is_lazy,
          };
          if (isBezelClub && !!code) buyData.code = code;
          isBezelClub
            ? purchasePartnerNFT(window.ADDRESSES.BEZEL.ETH, nft?._id, buyData)
            : purchaseNFTCreator(nft?._id, buyData, nft?.token_standard);

          setTx(res.data.transactionHash);
          setStatus(2);
        } else {
          setStatus(3);
          setError(res.data);
          closeModal();
        }
      }
    } catch (e) {
      setStatus(3);
      setError(e.message);
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: e.message,
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
      closeModal();
    }
  };

  const ethPrice = parseFloat(nft?.listing?.price / eth?.usd?.eth).toFixed(4);
  const sign = async () => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(chains[chain].rpcTarget)
    );
    const value =
      currency === ""
        ? web3.utils.toWei(ethPrice.toString())
        : web3.utils.toWei(price.toString(), "mwei");
    const lazyMintSignData = {
      seller: window.BEZEL_SELLER_ADDRESS,
      buyer: selectedWallet,
      currency: currency,
      price: value,
      domainName: "TBC",
      version: "1",
      chainId: window.CHAIN_ID_ETH,
      contract: window.ADDRESSES.BEZEL.ETH,
    };

    await getSignatureCreator(lazyMintSignData);
  };

  async function startLazyMint() {
    if (signature?.signature) {
      await lazyMint();
      signature.signature = null;
    }
  }

  const lazyMint = async (isFiat) => {
    console.log(isFiat);
    if (isFiat) {
      setStatus(1);

      const web3 = new Web3(
        new Web3.providers.HttpProvider(chains[chain].rpcTarget)
      );

      const buyPrice =
        nft?.listing?.currency === "USDC"
          ? web3.utils.toWei(nft?.listing?.price.toString(), "mwei")
          : web3.utils.toWei(nft?.listing?.price.toString());
      const royalty = nft?.royalties?.royalties?.map((e) => e?.royalty * 100);
      const creators =
        [nft?.creators?.minter?.create_wallet, ...nft?.creators?.partners]
          ?.length === 1 && nft?.royalties?.total_royalties === 0
          ? []
          : [nft?.creators?.minter?.create_wallet, ...nft?.creators?.partners];

      const lazyMintData = {
        tokenAddress: is1155
          ? NIFTYSouq1155.address[chain]
          : NIFTYSouq721.address[chain],
        uri: nft?.ipfs_path,
        seller: nft?.owner?.create_wallet,
        creators: creators,
        royalties:
          nft?.royalties?.total_royalties === 0 ? [] : royalty ? royalty : [],
        investors: nft?.creators?.partners,
        revenues: nft?.revenues,
        minPrice: buyPrice,
        quantity: nft?.editions?.total,
        signature: nft?.signature,
        currency: nft?.listing?.currency === "USDC" ? "USDC" : "",
      };

      const sc_input_data_buy = web3.eth.abi.encodeFunctionCall(
        {
          inputs: [
            {
              internalType: "uint256",
              name: "purchaseQuantity",
              type: "uint256",
            },
            {
              components: [
                {
                  internalType: "address",
                  name: "tokenAddress",
                  type: "address",
                },
                {
                  internalType: "string",
                  name: "uri",
                  type: "string",
                },
                {
                  internalType: "address",
                  name: "seller",
                  type: "address",
                },
                {
                  internalType: "address[]",
                  name: "creators",
                  type: "address[]",
                },
                {
                  internalType: "uint256[]",
                  name: "royalties",
                  type: "uint256[]",
                },
                {
                  internalType: "address[]",
                  name: "investors",
                  type: "address[]",
                },
                {
                  internalType: "uint256[]",
                  name: "revenues",
                  type: "uint256[]",
                },
                {
                  internalType: "uint256",
                  name: "minPrice",
                  type: "uint256",
                },
                {
                  internalType: "uint256",
                  name: "quantity",
                  type: "uint256",
                },
                {
                  internalType: "bytes",
                  name: "signature",
                  type: "bytes",
                },
                {
                  internalType: "string",
                  name: "currency",
                  type: "string",
                },
              ],
              internalType: "struct LazyMintSellData",
              name: "lazyMintSellData_",
              type: "tuple",
            },
            {
              internalType: "address",
              name: "_buyer",
              type: "address",
            },
          ],
          name: "lazyMintSellNft",
          outputs: [
            {
              internalType: "uint256",
              name: "offerId_",
              type: "uint256",
            },
            {
              internalType: "uint256",
              name: "tokenId_",
              type: "uint256",
            },
          ],
          stateMutability: "payable",
          type: "function",
        },
        [amount, lazyMintData, selectedWallet]
      );

      console.log({ sc_input_data_buy }, lazyMintData, amount, nft?.offer_id);
      const signedData = signSmartContractData(
        {
          commodity: nft?.listing?.currency === "USDC" ? "USDC" : chain,
          // commodities: "BTC,ETH,MATIC,USDC,TT",

          commodity_amount: isBezelClub
            ? (price * 1.025).toFixed(7)
            : chain === "ETH"
            ? (price * 1.025 * amount).toFixed(7)
            : (price * 1.025 * amount).toFixed(7),
          address: selectedWallet,
          pk_id: "key1",
          sc_address: window.ADDRESSES.MARKETPLACE[chain],
          sc_id: uuidv4(),
          sc_input_data: sc_input_data_buy,
        },
        window.WERT_PK
      );

      const wertWidget = new WertWidget({ ...signedData, ...options });
      wertWidget.setTheme({
        theme: "dark",
        colors: {
          color_buttons: "white",
        },
      });
      wertWidget.mount();
    } else {
      try {
        const web3 = new Web3(
          new Web3.providers.HttpProvider(chains[chain].rpcTarget)
        );
        const value =
          nft?.listing?.currency === "USDC"
            ? web3.utils.toWei(
                (
                  nft?.listing?.price +
                  nft?.listing?.price * 0.025 * amount
                ).toString(),
                "mwei"
              )
            : web3.utils.toWei(
                (
                  nft?.listing?.price +
                  nft?.listing?.price * 0.025 * amount
                ).toString()
              );
        const buyPrice =
          nft?.listing?.currency === "USDC"
            ? web3.utils.toWei(nft?.listing?.price.toString(), "mwei")
            : web3.utils.toWei(nft?.listing?.price.toString());
        const royalty = nft?.royalties?.royalties?.map((e) => e?.royalty * 100);
        const creators =
          [nft?.creators?.minter?.create_wallet, ...nft?.creators?.partners]
            ?.length === 1 && nft?.royalties?.total_royalties === 0
            ? []
            : [
                nft?.creators?.minter?.create_wallet,
                ...nft?.creators?.partners,
              ];
        let buyEditions = [];

        if (is1155) {
          const user = await extractLoggedInOwner(
            selectedOwner?.user?._id,
            nft?.owners
          );
          buyEditions = await getEditions(user?.listed_editions, amount);
        }
        const lazyMintData = {
          tokenAddress: is1155
            ? NIFTYSouq1155.address[chain]
            : NIFTYSouq721.address[chain],
          uri: nft?.ipfs_path,
          seller: nft?.owner?.create_wallet,
          creators: creators,
          royalties:
            nft?.royalties?.total_royalties === 0 ? [] : royalty ? royalty : [],
          investors: nft?.creators?.partners,
          revenues: nft?.revenues,
          minPrice: buyPrice,
          quantity: nft?.editions?.total,
          signature: nft?.signature,
          currency: nft?.listing?.currency === "USDC" ? "USDC" : "",
        };
        console.log({
          amount,
          lazyMintData,
          value,
          currency: nft?.listing?.currency === "USDC" ? "USDC" : "",
          selectedWallet,
          status: selectedWallet === props.defaultWallet,
        });

        const res = await lazyMintToken(
          amount,
          lazyMintData,
          value,
          nft?.listing?.currency === "USDC" ? "USDC" : "",
          selectedWallet,
          selectedWallet === props.defaultWallet
        );

        if (res.status === false) {
          Swal.fire({
            icone: "error",
            title: t("oops"),
            text: res.data.message,
            color: props?.colors?.text,
            background: props?.colors?.bg,
            confirmButtonText: t("Ok"),
            confirmButtonColor: "#6d3190",
          });
        }

        const buyLazyData = {
          price,
          wallet: selectedWallet,
          tx: res.data.res.transactionHash,
          token_id:
            nft?.token_standard === "1155"
              ? res.data.res.events.eMint[0].returnValues.tokenId
              : res.data.res.events.eMint.returnValues.tokenId,
          owner:
            nft?.token_standard === "1155"
              ? selectedOwner.user?.create_wallet
              : nft?.owner?.create_wallet,
          editions: nft?.token_standard === "1155" ? buyEditions : [],
          currency: isBezelClub ? currency : chain,
          is_lazy: nft?.is_lazy,
          offer_id:
            nft?.token_standard === "1155" ? res.data.result.offerId_ : "0",
        };
        if (res.status) {
          purchaseNFTCreator(nft?._id, buyLazyData, nft?.token_standard);
          setTx(res.data.res.transactionHash);
          setStatus(2);
        }
      } catch (e) {
        setStatus(3);
        setError(e.message);
      }
    }
  };

  useEffect(() => {
    setWalletType("nifty");
    setSelectWallet(props.defaultWallet);
  }, [props.defaultWallet]);

  useEffect(() => {
    const interval = setInterval(async () => {
      console.log(wertPaymentStatus, txHash, wertPosition);
      // callApi({ click_id: options.click_id });
      if (
        txHash === "" &&
        wertPaymentStatus === "pending" &&
        wertStatus === 4
      ) {
        setWertStatus(5);
      }
      if (
        txHash !== "" &&
        wertPaymentStatus === "pending" &&
        wertStatus === 5
      ) {
        setWertStatus(6);
      }
      if (wertPaymentStatus === "success" && wertStatus === 6) {
        setWertStatus(7);
      }
      if (wertPaymentStatus === "failed") {
        setWertStatus(8);
        setStatus(3);
      }
      console.log(wertStatus);
      const web3 = new Web3(
        new Web3.providers.HttpProvider(chains[chain].rpcTarget)
      );

      const gasAmount = window.GAS_LIMIT[chain];

      console.log("gas Amount ........>", gasAmount);
      const gasPrice = parseInt(
        web3.utils.fromWei(
          ((await web3.eth.getGasPrice()) * 10 ** 9).toString(),
          "gwei"
        )
      );
      console.log("gas Price ---->", gasPrice);
      const gasFee = web3.utils.fromWei(
        parseInt(parseInt(gasPrice) * parseInt(gasAmount)).toString()
      );
      console.log("gas Fees ====>", gasFee);
      setGasFees(gasFee);
      let buyEditions = [];

      if (is1155) {
        const user = await extractLoggedInOwner(
          selectedOwner?.user?._id,
          nft?.owners
        );
        buyEditions = await getEditions(user?.listed_editions, amount);
      }
      console.log("Status====>", status, "txHash===>", txHash);
      if((txHash?.includes("0x")) && (status < 2 )) {
        console.log("Save To Backend", status, wertPaymentStatus);
        getWertOrder({
          click_id: options.click_id,
          wallet: selectedWallet,
          nft: nft?._id,
          editions: nft?.token_standard === "1155" ? buyEditions : [],
          price: price,
          tx: txHash,
          network: getNetworkFromCurrency(nft?.blockchain),
          owner:
            nft?.token_standard === "1155"
              ? selectedOwner.user?.create_wallet
              : nft?.owner?.create_wallet,
        });
        if (isFiat) {
          const txEvents = await web3.eth.getTransactionReceipt(txHash);
          console.log(txEvents);

          setStatus(2);
          setFiatPay(false);
        }
      };
    }, 5000);

    return () => clearInterval(interval);
  }, [
    selectedWallet,
    wertPaymentStatus,
    gasFees,
    status,
    txHash,
    wertStatus,
    amount,
    chain,
    currency,
    is1155,
    isFiat,
    nft,
    price,
    selectedOwner,
  ]);

  const Completionist = () => <span>Almost There...</span>;

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <Completionist />;
    } else {
      // Render a countdown
      return wertPaymentStatus === "success" ? (
        <span>{t("Done")}</span>
      ) : (
        <span>
          {minutes < 10 ? "0" + minutes : minutes}:
          {seconds < 10 ? "0" + seconds : seconds}
        </span>
      );
    }
  };

  return (
    <div>
      {connectWalletModal && (
        <WalletModal
          colors={props.colors}
          isOpen={connectWalletModal}
          setIsOpen={setConnectWalletModal}
        />
      )}
      <ModalAndOverlay colors={props.colors}>
        <div>
          <div className="d-flex justify-content-between mb-4">
            <h3 className="modal-heading" style={{ color: props.colors?.text }}>
              {t("buy_with_card")}
            </h3>
            {(status === 2 || txHash === "") && (
              <CloseIcon
                className="modal-close-icon"
                color={props.colors?.text1}
                onClick={() => {
                  setFiatPay(false);
                  closeModal();
                }}
              />
            )}
          </div>

          <div
            id="load"
            className="d-flex justify-content-center align-items center"
          >
            <div style={{ paddingBottom: "30px" }}>
              {wertStatus === 7 ? (
                <Image
                  width={48}
                  height={48}
                  style={{ maxWidth: "auto" }}
                  src={completedIcon}
                ></Image>
              ) : wertStatus === 8 ? (
                ""
              ) : (
                <Spinner animation="border" variant="primary" />
              )}

              {wertStatus === 5
                ? "processing your card payment"
                : wertStatus === 7
                ? "your purchase was processed successfully "
                : wertStatus === 6
                ? "order successfully sent to the smart contract "
                : wertStatus === 8
                ? "Failed"
                : "card charged, calling the smart contract"}
              {wertStatus === 6 ? (
                <>
                  <div style={{ fontSize: "9px", textAlign: "center" }}>
                    {t(
                      "This transaction will take approximatily 3 minutes to be confirmed."
                    )}
                    <br />
                    {t("Please don't close this window.")}
                  </div>
                  <p style={{ fontSize: "16px", textAlign: "center" }}>
                    <Countdown date={Date.now() + 180000} renderer={renderer} />
                  </p>{" "}
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          {isBezelClub &&
            (clientSecret ? (
              <Elements
                stripe={stripeKey}
                options={{
                  clientSecret,
                  appearance: {
                    theme: "night",
                  },
                }}
              >
                <StripeCheckoutForm
                  nftId={nft?._id}
                  colors={props.colors}
                  externalNFT={externalNFT}
                />
              </Elements>
            ) : (
              <div className="d-flex justify-content-center align-items center">
                <Spinner animation="border" color="#fff" />
              </div>
            ))}
          {!isBezelClub && (
            <>
              <div id="wert-widget"></div>
              {status === 2 || wertPaymentStatus === "failed" ? (
                <Button
                  className={`modal-button ${props?.colors?.button}`}
                  onClick={() => {
                    setFiatPay(false);
                  }}
                >
                  {wertPaymentStatus === "failed" ? t("Failed") : t("Done")}
                </Button>
              ) : (
                <Button
                  className={`modal-button ${props?.colors?.button}`}
                  disabled={true}
                >
                  {t("We are purchasing your NFT, please wait...")}
                </Button>
              )}
            </>
          )}
        </div>
      </ModalAndOverlay>
      {!isFiat && (
        <ModalAndOverlay colors={props.colors}>
          <Checkout colors={props.colors}>
            <div className="d-flex justify-content-between mb-3">
              <div className="modal-heading">
                <div className="title">{t("Checkout")}</div>
              </div>
              <CloseIcon
                className="modal-close-icon"
                color={props.colors?.text1}
                onClick={closeModal}
              />
            </div>
            {/* error */}
            {status === 3 ? (
              <div>
                <h4 className="success-message">{t("transaction_failed")}</h4>
                <div className="d-flex table-row">
                  <span>{error.toString()}</span>
                </div>
              </div>
            ) : //  success
            status === 2 ? (
              <div>
                <div className="d-flex flex-column">
                  <div className="success-message">
                    {t("successfully_bought")}
                    <strong className="mx-1">{nft?.name}</strong>
                  </div>
                  <div className="success-message_1 mt-3">
                    {t("congratulations_successfully_bought")}
                    <strong className="mx-1">{nft?.name}</strong>
                    {t("on")}
                    <strong className="mx-1">{t("Nifty Souq")}</strong>
                  </div>
                  {nft?.type === "image" ? (
                    <Image
                      height={250}
                      className="my-4"
                      style={{ objectFit: "contain" }}
                      src={isBezelClub ? nft?.image_url : nft?.url}
                    />
                  ) : nft?.type === "video" ? (
                    <video
                      loop
                      muted
                      autoPlay
                      height={250}
                      className="my-4"
                      style={{ objectFit: "contain" }}
                      playsInline
                    >
                      <source
                        src={isBezelClub ? nft?.image_url : nft?.url}
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <div
                      style={{ height: 200 }}
                      className="my-4 d-flex align-items-center justify-content-center"
                    >
                      <audio controls>
                        <source src={nft?.url} type="audio/mp3" />
                      </audio>
                    </div>
                  )}
                </div>

                <Button className="modal-button mb-3" onClick={closeModal}>
                  {t("View NFT")}
                </Button>
              </div>
            ) : (
              <>
                {status === 0 ? (
                  <div>
                    <p style={{ fontWeight: 350 }}>
                      {t("You are about to purchase a")}{" "}
                      <span>{nft?.name}</span> {t("from")}
                      <span>
                        {" "}
                        {isBezelClub
                          ? nft?.owner?.name
                          : is1155
                          ? selectedOwner?.user.name
                          : nft?.creators?.minter?.name}
                      </span>
                    </p>
                    {isBezelClub ? (
                      <div>
                        <Row>
                          <Col>
                            <Form.Check
                              type="radio"
                              name="paymentType"
                              value="card"
                              label={t("Debit/Credit card")}
                              checked={paymentType === "card"}
                              onChange={(e) => {
                                if (e.target.checked) setPaymentType("card");
                              }}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="radio"
                              name="paymentType"
                              value="wallet"
                              label={t("Wallet")}
                              checked={paymentType === "wallet"}
                              onChange={(e) => {
                                if (e.target.checked) setPaymentType("wallet");
                              }}
                            />
                          </Col>
                        </Row>
                        <p className="uppercase mt-4">{t("Purchase using")} </p>
                        <div>
                          <Form.Check
                            type="radio"
                            name="walletType"
                            value="nifty"
                            label={t("Nifty wallet")}
                            checked={walletType === "nifty"}
                            onChange={(e) => {
                              setSelectWallet(props.walletOptions[0]?.value);
                              if (e.target.checked) setWalletType("nifty");
                            }}
                          />
                          <div>{props.walletOptions[0]?.value}</div>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                          <div>Balance</div>
                          <div className="mx-2">
                            {" "}
                            {parseFloat(props.defaultBalance).toFixed(4)}{" "}
                            {chain}
                          </div>
                        </div>
                        <div className="d-flex flex-row align-items-center my-3">
                          <div
                            style={{
                              flex: 1,
                              height: 1,
                              backgroundColor: "#fff",
                            }}
                          ></div>
                          <div style={{ textAlign: "center", flex: 0.5 }}>
                            OR
                          </div>
                          <div
                            style={{
                              flex: 1,
                              height: 1,
                              width: "100%",
                              backgroundColor: "#fff",
                            }}
                          ></div>
                        </div>
                        <div>
                          <Form.Check
                            type="radio"
                            name="walletType"
                            value="external"
                            disabled={props.walletOptions?.length <= 1}
                            label={t("External wallet")}
                            checked={walletType === "external"}
                            onChange={(e) => {
                              setSelectWallet(props.walletOptions[1]?.value);
                              if (e.target.checked) setWalletType("external");
                            }}
                          />
                          <div>
                            {props.walletOptions?.length > 1
                              ? props.walletOptions[1]?.value
                              : "Disconnected"}
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <div>Balance</div>
                            <div className="mx-2">
                              {" "}
                              {parseFloat(props.accountBalance).toFixed(4)}{" "}
                              {chain}
                            </div>
                          </div>
                        </div>
                        <Form.Group className="my-3" controlId="formBasicEmail">
                          <Button
                            className="modal-button mb-2 mt-2"
                            onClick={() => setConnectWalletModal(true)}
                          >
                            {props.walletOptions?.length > 1
                              ? t("External wallet connected")
                              : t("Connect external wallet")}
                          </Button>
                        </Form.Group>
                        {paymentType === "wallet" && (
                          <Form.Group className="mb-4">
                            <Form.Label>
                              <div>{t("Currency")}</div>
                            </Form.Label>
                            <Form.Select
                              type="text"
                              placeholder={t("referral_code")}
                              className="create-page-input-field"
                              value={currency}
                              onChange={(e) => setCurrency(e.target.value)}
                              style={{
                                color: props.colors?.text,
                                backgroundColor: props.colors?.bg,
                              }}
                            >
                              <option value={""}>{"ETH"}</option>
                              <option value={"USDC"}>{"USDC"}</option>
                            </Form.Select>
                          </Form.Group>
                        )}
                        <Form.Group className="mb-4">
                          <Form.Label>
                            <div>{t("referral_code")}</div>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("referral_code")}
                            className="create-page-input-field"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            style={{
                              color: props.colors?.text,
                              backgroundColor: props.colors?.bg,
                            }}
                          />
                        </Form.Group>
                      </div>
                    ) : (
                      <div>
                        {is1155 && (
                          <>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                <div>{t("Amount")}</div>
                              </Form.Label>
                              <Form.Control
                                min={1}
                                step={1}
                                type="number"
                                value={amount}
                                placeholder={t("amount_placeholder")}
                                onChange={(e) => setAmount(e.target.value)}
                                max={
                                  selectedOwner?.listed_editions?.length || 1
                                }
                                style={{
                                  color: props.colors?.text,
                                  backgroundColor: props.colors?.bg,
                                }}
                              />
                            </Form.Group>
                          </>
                        )}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Button
                            className="modal-button mb-2 mt-2"
                            onClick={() => setConnectWalletModal(true)}
                          >
                            {props.walletOptions?.length > 1
                              ? t("External wallet connected")
                              : t("Connect external wallet")}
                          </Button>
                          <Form.Label>
                            <div>{t("Wallets")}</div>
                          </Form.Label>
                          <Form.Select
                            className="mb-3"
                            style={{
                              color: props?.colors?.text,
                              backgroundColor: props?.colors?.bg,
                            }}
                            value={selectedWallet}
                            onChange={(e) => setSelectWallet(e.target.value)}
                          >
                            <WalletDropDownList />
                          </Form.Select>
                        </Form.Group>
                      </div>
                    )}
                    <div className="d-flex justify-content-between enter-bid mb-3 pb-1">
                      <div>{t("Price")}</div>
                      <span>
                        {nft?.token_standard === "1155"
                          ? Math.round(selectedOwner?.price * amount * 100000) /
                            100000
                          : Math.round(nft?.listing?.price * amount * 100000) /
                            100000}{" "}
                        {isBezelClub ? "USD" : nft?.listing?.currency}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between enter-bid mb-3 pb-1">
                      <div>{t("Service fee")}</div>
                      <span>
                        {(
                          (nft?.token_standard === "1155"
                            ? selectedOwner?.price * 0.025
                            : nft?.listing?.price * 0.025) * amount
                        ).toFixed(6)}
                        &nbsp;
                        {isBezelClub ? "USD" : nft?.listing?.currency}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between enter-bid mb-3 pb-1">
                      <div>{t("Gas fee estimation")}</div>

                      <span>
                        {gasFees === 0
                          ? "calculating gas fee..."
                          : parseFloat(gasFees).toFixed(6)}
                        &nbsp;
                        {gasFees === 0
                          ? ""
                          : isBezelClub
                          ? "USD"
                          : nft?.listing?.currency}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between enter-bid mb-3 pb-1">
                      <div>{t("You will pay")}</div>
                      <span>
                        {gasFees === 0
                          ? "calculating total price..."
                          : (
                              (nft?.token_standard === "1155"
                                ? selectedOwner?.price * 1.025
                                : nft?.listing?.price * 1.025) *
                                amount +
                              parseFloat(gasFees)
                            ).toFixed(6)}
                        &nbsp;
                        {gasFees === 0
                          ? ""
                          : isBezelClub
                          ? "USD"
                          : nft?.listing?.currency}
                      </span>
                    </div>
                    <div style={{ fontSize: "14px", textAlign: "center" }}>
                      {t(
                        "This is an estimation, the price might be varied depends on the market gas price change."
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="mb-4 pb-2">
                    <div className="d-flex align-items-center mb-3">
                      <div>
                        {status === 1 ? (
                          <Spinner animation="border" variant="primary" />
                        ) : status > 1 ? (
                          <Image src={completedIcon}></Image>
                        ) : (
                          <Image src={lockOrderIcon}></Image>
                        )}
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <h5 className="m-0 modal-section-title title">
                          <strong>{t("Purchasing")}</strong>
                        </h5>
                        <p className="m-0 modal-section-text title">
                          {t("Call contract method")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-4">
                  {(!isBezelClub || paymentType === "wallet") && (
                    <Button
                      className="modal-button mb-3"
                      id="buy-crypto"
                      disabled={status === 1 || gasFees === 0}
                      style={
                        selectedWallet
                          ? {}
                          : {
                              pointerEvents: "none",
                              cursor: "not-allowed",
                              opacity: "0.65",
                            }
                      }
                      onClick={() => {
                        nft?.blockchain === chain
                          ? status === 0
                            ? nft?.is_lazy
                              ? lazyMint()
                              : buy()
                            : closeModal()
                          : Swal.fire({
                              icone: "error",
                              title: t("oops"),
                              text: t("switch_network"),
                              color: props?.colors?.text,
                              background: props?.colors?.bg,
                              confirmButtonText: t("Ok"),
                              confirmButtonColor: "#6d3190",
                            });
                      }}
                    >
                      {status === 0
                        ? t("Proceed to payment with crypto")
                        : status === 1
                        ? t("Calling...")
                        : t("View NFT")}
                    </Button>
                  )}
                  {(!isBezelClub || paymentType === "card") &&
                    chain !== "BOBA" && (
                      <Button
                        className={`modal-button mb-3 ${props?.colors?.button}`}
                        id="buy-fiat"
                        disabled={status != 0 || gasFees === 0}
                        onClick={async () => {
                          setFiatPay(true);

                            if (status === 0) {
                              if (nft?.is_lazy) lazyMint(true);
                              else buy(true);
                            } else closeModal(true);
                          }
                        }
                      >
                        {t("Pay with card")}
                        <img
                          src={visamaster}
                          className="no-rotate"
                          width="80"
                          height="30"
                        />
                      </Button>
                    )}
                  <Button
                    onClick={closeModal}
                    disabled={status === 1}
                    className={`modal-button ${props?.colors?.button}`}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </>
            )}
          </Checkout>
        </ModalAndOverlay>
      )}
    </div>
  );
};

export default BuyOfferModal;
