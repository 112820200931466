/* eslint-disable no-undef */
import { useEffect, useState } from "react";
//web3
import Web3 from "web3";
import WalletModal from "./WalletModal";
//bootstrap
import { Button, Form, Row, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ModalAndOverlay from "./ModalAndOverlay";
// grey icons
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { bidActionsCreators, walletActionsCreators } from "../store";
//config and library
import { useWeb3Context } from "@context/Web3";
import { useWeb3Auth } from "@context/Web3auth";
import { useWeb3React } from "@web3-react/core";
import { chains } from "@config";

import Swal from "sweetalert2";
//core
import { convertDateToSeconds } from "../core/nft";
import WalletDropDownList from "./WalletDropDownList";

const PlaceBid = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  h4 {
    font-size: 24px;
    color: ${(props) => props.colors?.text1};
  }
  h6 {
    font-weight: 500;
    color: ${(props) => props.colors?.text2};
  }
  p {
    margin-bottom: 8px;
    color: ${(props) => props.colors?.text};
  }
  span {
    color: ${(props) => props.colors?.text1};
  }
  .enter-bid {
    border-bottom: 1px solid #e6e8ec;
  }
`;

const PlaceBidModals = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [walletOptions, setWalletOptions] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [defaultWallet, setDefaultWallet] = useState("");
  const [balance, setBalance] = useState("");
  const [accountbalance, setAccountBalance] = useState("");
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  const { default_wallet, another_wallet } = useSelector(
    (state) => state.wallets
  );
  const { placeBidCreator } = bindActionCreators(bidActionsCreators, dispatch);
  const { nft } = useSelector((state) => state.nft_details);
  const { chain, privateKey, getAccounts, chainId } = useWeb3Auth();
  const { account } = useWeb3React();
  const { placeBid, lazyPlaceBid } = useWeb3Context();
  const [status, setStatus] = useState(0);
  const [bidAmount, setBidAmount] = useState(
    nft?.highest_bid?.price
      ? parseFloat(nft?.highest_bid?.price).toFixed(3)
      : parseFloat(nft?.listing?.min_bid).toFixed(3)
  );
  const getWallets = async () => {
    // to get default wallet from web3auth
    const wallet = await getAccounts();
    setBalance(wallet.balance);
    setDefaultWallet(wallet.address);
    setSelectedWallet(wallet.address);

    const wallets = [];
    wallets.push({
      value: wallet.address,
      label: wallet.address,
      balance: wallet.balance,
    });
    // to push another_wallet to wallets array
    if (account) {
      if (chains[chain].chainId === chainId) {
        wallets.push({ value: account, label: account });
      }
    }
    setWalletOptions(props.walletOptions);

    const web3 = new Web3(
      new Web3.providers.HttpProvider(chains[chain].rpcTarget)
    );
    // to get external wallet balance
    const accountBalance = await web3.eth.getBalance(account);
    const accountbalance = web3.utils.fromWei(accountBalance, "ether");

    Promise.all(wallets).then(() => {});
    setAccountBalance(accountbalance);
  };

  useEffect(() => {
    getWallets();
  }, [privateKey, account, balance, accountbalance]);

  const addBid = async () => {
    try {
      setStatus(1);
      const web3 = new Web3(
        new Web3.providers.HttpProvider(chains[chain].rpcTarget)
      );

      const royalty = nft?.royalties?.royalties.map((e) => e.royalty * 100);
      const creators =
        [nft?.creators?.minter?.create_wallet, ...nft?.creators?.partners]
          ?.length === 1 || nft?.royalties?.total_royalties === 0
          ? []
          : [nft?.creators?.minter?.create_wallet, ...nft?.creators?.partners];
      const date = new Date(nft?.listing?.auction_start_date);
      // const startDate = date.getTime();
      // const date = Date.now();

      const startDate = Math.floor(new Date(date).getTime() / 1000);
      const duration = nft?.listing?.auction_duration * 86400;
      const biddingAmount = web3.utils.toWei(bidAmount.toString());
      if (nft?.status === "LAZY_MINT") {
        const lazyMintAuctionData = {
          tokenAddress: nft?.contract,
          uri: nft?.ipfs_path,
          seller: nft?.owner_wallet,
          creators: creators,
          royalties:
            nft?.royalties?.total_royalties === 0 ? [] : royalty ? royalty : [],
          investors: nft?.creators?.partners,
          revenues: nft?.revenues,
          startTime: startDate,
          duration: duration,
          startBidPrice: web3.utils.toWei(nft?.listing?.min_bid.toString()),
          reservePrice: web3.utils.toWei(nft?.listing?.price.toString()),
          signature: nft?.signature,
        };

        const result = await lazyPlaceBid(
          lazyMintAuctionData,
          biddingAmount,
          selectedWallet === props.defaultWallet
        );
        if (result.status) {
          const bidData = {
            nft_id: nft?._id,
            wallet: selectedWallet,
            price: bidAmount,
            bid_index: result.data.res.events.ePlaceBid.returnValues.BidIdx,
            token_id: result.data.result.tokenId_,
            tx: result.data.res.transactionHash,
            offer_id: result.data.res.events.ePlaceBid.returnValues.offerId,
          };
          placeBidCreator(bidData);
          setStatus(2);
        }
      } else {
        const res = await placeBid(
          nft?.offer_id,
          biddingAmount,
          selectedWallet === props.defaultWallet
        );

        const bidData = {
          nft_id: nft._id,
          wallet: selectedWallet,
          price: bidAmount,
          bid_index: res.data.events.ePlaceBid.returnValues.BidIdx,
          tx: res.data.transactionHash,
          offer_id: nft?.offer_id,
        };
        // save to backend
        if (res.status) {
          //setResponse(res.data);
          placeBidCreator(bidData);
          setStatus(2);
        } else {
          Swal.fire({
            icone: "error",
            title: t("oops"),
            text: t("transaction_failed"),
            color: props?.colors?.text,
            background: props?.colors?.bg,
            confirmButtonText: t("Ok"),
            confirmButtonColor: "#6d3190",
          });
          props.closeOfferModal();
        }
      }
    } catch (e) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: t("bidding_failed"),
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
      props.closeOfferModal();
    }
  };

  return (
    <ModalAndOverlay colors={props.colors}>
      {connectWalletModal && (
        <WalletModal
          colors={props.colors}
          isOpen={connectWalletModal}
          setIsOpen={setConnectWalletModal}
        />
      )}
      {/* --------- place a bid --------- */}
      <PlaceBid colors={props.colors}>
        <div className="d-flex justify-content-between mb-5">
          <h3 className="modal-heading title">{t("Place a Bid")}</h3>
          <CloseIcon
            className="modal-close-icon"
            color={props.colors?.text1}
            onClick={props.closeOfferModal}
          />
        </div>
        {chain !== nft?.blockchain ? (
          <>
            <h4> {t("Please switch network")}</h4>
          </>
        ) : (
          ""
        )}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>{t("Enter Bid")}</Form.Label>

          {selectedWallet === defaultWallet ? (
            balance < nft?.listing?.min_bid && balance ? (
              <div>
                <InputGroup>
                  <Form.Control
                    type="text"
                    disabled
                    placeholder={t("enter_bid_placeholder")}
                    style={{
                      color: props?.colors?.text,
                      backgroundColor: props?.colors?.bg,
                    }}
                  />
                  <InputGroup.Text>
                    {chain === "ETH" ? t("wETH") : t("wMATIC")}
                  </InputGroup.Text>
                </InputGroup>
                {bidAmount > 0 && (
                  <p style={{ color: "red", paddingTop: "10px", fontSize: 14 }}>
                    {t("You don't have enough balance in your wallet")}
                  </p>
                )}
              </div>
            ) : (
              <>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={bidAmount}
                    onChange={(e) => setBidAmount(e.target.value)}
                    placeholder={t("enter_bid_placeholder")}
                    style={{
                      color: props?.colors?.text,
                      backgroundColor: props?.colors?.bg,
                    }}
                  />
                  <InputGroup.Text>
                    {chain === "ETH" ? t("wETH") : t("wMATIC")}
                  </InputGroup.Text>
                </InputGroup>
                {(nft?.highest_bid?.price
                  ? bidAmount <= parseFloat(nft?.highest_bid?.price).toFixed(3)
                  : bidAmount <
                    parseFloat(nft?.listing?.min_bid).toFixed(3)) && (
                  <p
                    style={{
                      color: "red",
                      paddingTop: "10px",
                      fontSize: 14,
                    }}
                  >
                    {t("unacceptable bid value")}
                  </p>
                )}
              </>
            )
          ) : accountbalance < nft?.listing?.min_bid && accountbalance ? (
            <div>
              <InputGroup size="md">
                <Form.Control
                  type="text"
                  disabled
                  placeholder={t("enter_bid_placeholder")}
                  style={{
                    color: props?.colors?.text,
                    backgroundColor: props?.colors?.bg,
                  }}
                />
                <InputGroup.Text>
                  {chain === "ETH" ? t("wETH") : t("wMATIC")}
                </InputGroup.Text>
              </InputGroup>
              <p style={{ color: "red", paddingTop: "10px", fontSize: 14 }}>
                {t("You don't have enough balance in your wallet")}
              </p>
            </div>
          ) : (
            <>
              <InputGroup size="md">
                <Form.Control
                  type="text"
                  value={bidAmount}
                  style={{
                    color: props?.colors?.text,
                    backgroundColor: props?.colors?.bg,
                  }}
                  onChange={(e) => setBidAmount(e.target.value)}
                  placeholder={t("enter_bid_placeholder")}
                />
                <InputGroup.Text>
                  {chain === "ETH" ? t("wETH") : t("wMATIC")}
                </InputGroup.Text>
              </InputGroup>
              {bidAmount <
                (nft?.highest_bid?.price
                  ? parseFloat(nft?.highest_bid?.price).toFixed(3)
                  : parseFloat(nft?.listing?.min_bid).toFixed(3)) && (
                <p
                  style={{
                    color: "red",
                    paddingTop: "10px",
                    fontSize: 14,
                  }}
                >
                  {t("unacceptable bid value")}
                </p>
              )}
            </>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="wallet-address">
          <Button
            className="modal-button mb-4 mt-4"
            onClick={() => setConnectWalletModal(true)}
          >
            {walletOptions?.length > 1
              ? t("External wallet connected")
              : t("Connect external wallet")}
          </Button>
          <Form.Label>
            <div>{t("Wallet address")}</div>
          </Form.Label>
          <Form.Select
            aria-label="Select wallet"
            className="payment-page-input select"
            onChange={(e) => setSelectedWallet(e.target.value)}
            value={selectedWallet}
            style={{
              color: props?.colors?.text,
              backgroundColor: props?.colors?.bg,
            }}
          >
            <WalletDropDownList />
          </Form.Select>
          <Row
            style={{
              marginTop: 7,
              letterSpacing: -0.02,
              fontFamily: "Open Sans",
              position: "relative",
              color: "#777E90",
            }}
          ></Row>
        </Form.Group>

        <div className="d-flex justify-content-between">
          <p>{t("Your balance")}</p>
          {selectedWallet === defaultWallet ? (
            <span>
              {parseFloat(balance).toFixed(3)} {chain}
            </span>
          ) : (
            <span>
              {" "}
              {parseFloat(accountbalance).toFixed(3)} {chain}
            </span>
          )}
        </div>
        <div className="d-flex justify-content-between">
          <p>{t("Minimum bid")}</p>
          <span>
            {parseFloat(nft?.listing?.min_bid).toFixed(3)} {chain}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <p>{t("Highest bid")}</p>
          <span>
            {nft?.highest_bid?.price
              ? parseFloat(nft?.highest_bid?.price).toFixed(3)
              : 0}{" "}
            {chain}
          </span>
        </div>

        <Button
          disabled={bidAmount == 0 ? true : false}
          className="modal-button mb-3 mt-5"
          style={
            selectedWallet
              ? {}
              : {
                  pointerEvents: "none",
                  cursor: "not-allowed",
                  opacity: "0.65",
                }
          }
          onClick={
            status === 2
              ? props.closeOfferModal
              : () => {
                  addBid();
                }
          }
        >
          {status === 0
            ? t("Confirm")
            : status === 1
            ? t("Placing your bid...")
            : status === 2
            ? t("You have successfully placed a bid")
            : t("Error")}
        </Button>
        {/* <Button
          className={`modal-button ${props?.colors?.button}`}
          onClick={props.closeOfferModal}
        >
          {t("Cancel")}
        </Button> */}
      </PlaceBid>
    </ModalAndOverlay>
  );
};

export default PlaceBidModals;
