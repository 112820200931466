import * as yup from "yup";

const urlMatches =
  /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9_.#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const updateProfileSchema = yup.object().shape({
  name: yup.string().required(),
  bio: yup.string(),
  website: yup
    .string()
    .matches(urlMatches, "website must be a valid url")
    .nullable(),
  twitter: yup
    .string()
    .matches(urlMatches, "twitter must be a valid url")
    .nullable()
    .matches("twitter.com", "twitter must be a valid url"),
  instagram: yup
    .string()
    .matches(urlMatches, "instagram must be a valid url")
    .nullable()
    .matches("instagram.com", "instagram must be a valid url"),
  youtube: yup
    .string()
    .matches(urlMatches, "youtube must be a valid url")
    .nullable()
    .matches("youtube.com", "youtube must be a valid url"),
  dribble: yup
    .string()
    .matches(urlMatches, "dribble must be a valid url")
    .nullable(),
  facebook: yup
    .string()
    .matches(urlMatches, "facebook must be a valid url")
    .nullable()
    .matches("facebook.com", "facebook must be a valid url"),
});

export const createNftSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  categories: yup.string().required(),
});
