import React from "react";
import { StylesWrapperDefault, StylesWrapperFullView } from "./styles";

const NFTMedia = ({ nft, fullView = false, linkedImg }) => {
  const StylesWrapper = fullView ? StylesWrapperFullView : StylesWrapperDefault;
  return (
    <StylesWrapper fullView={fullView}>
      {linkedImg && <img className="img-container" src={linkedImg} alt="" />}
      {nft?.type === "image" ? (
        <img className="img-container" src={nft?.url} alt="" />
      ) : nft?.type === "video" ? (
        <video
          loop
          muted
          autoPlay
          controls
          controlsList="nodownload"
          className="img-container"
          playsInline
        >
          <source src={nft?.url} type="video/mp4" />
        </video>
      ) : (
        nft?.type === "audio" && (
          <audio
            loop
            muted
            autoPlay
            controls
            controlsList="nodownload"
            className="audio-container"
          >
            <source src={nft?.url} type="audio/mp3" />
          </audio>
        )
      )}
    </StylesWrapper>
  );
};
export default NFTMedia;
